import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";

import useFields from "../../../../../shared/util/hooks/useFields";
import Loading from "../../../../../shared/components/common/Loading";
import {
  SECTION,
  SECTION_NAME,
} from "../../../../../shared/util/constants/section";
import { PROGRAM_TYPE } from "../../../../../shared/util/constants/programType";
import Section from "../../../../../shared/components/FundRequest/StepsContainer/Section";
import { InputPropsBuilder } from "../../../../../shared/components/FundRequest/InputPropsBuilder";
import { InputFactory } from "../../../../../shared/components/FundRequest/InputFactory";

import { filterFields, getTemplate, schema } from "../../../../config/schema";
import { useOptionsContext } from "../../../../util/context/OptionsContext";
import { getFundRequest } from "../../../../util/services/data/FundRequestService";
import { DisableFactory } from "../Inputs/PropBuilder/DisableFactory";
import { ValueFactory } from "../Inputs/PropBuilder/ValueFactory";
import { FileUploadTriggers } from "../../../../util/services/data/dataFactory";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

const ProjectInformationSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];
  const [{ fieldTemplate, isLoading }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  const [isSCAFlagEnabled, setIsSCAFlagEnabled] = React.useState(false);

  // Check feature flag
  React.useEffect(async () => {
    if (data["program"] === PROGRAM_TYPE.MDF) {
      try {
        setIsSCAFlagEnabled(
          await isFeatureEnabled(FEATURE_FLAG_ENUMS.SCA_FUNDING_ENABLED)
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  const WwpsSection = () => {
    return (
      <Section
        header={<Header variant="h2">{SECTION.PUBLIC_SECTOR_DETAILS}</Header>}
      >
        <React.Fragment>
          {fieldTemplate &&
            fieldTemplate[SECTION_NAME.PUBLIC_SECTOR_DETAILS] &&
            fieldTemplate[SECTION_NAME.PUBLIC_SECTOR_DETAILS].fields.map(
              (field, index) => {
                if (
                  data["program"] === PROGRAM_TYPE.MDF &&
                  field.name === "wwpsConsent" &&
                  isSCAFlagEnabled
                ) {
                  field.label = "";
                  field.description = (
                    <React.Fragment>
                      By proceeding forward, you represent and warrant that you
                      have the legal authority to bind the APN Partner applying
                      for funding Benefits (“Partner”) and that Partner agrees
                      to the following:
                      <br />
                      <br />
                      Partner certifies that the project for which Partner is
                      applying for funding Benefits (“Project”) meets the
                      requirements for the applicable project type under the
                      current APN Funding Guide, and does not relate to any
                      individual Government customer (as defined in the{" "}
                      <a
                        href={
                          "https://aws.amazon.com/partners/terms-and-conditions/"
                        }
                      >
                        APN Terms & Conditions
                      </a>{" "}
                      ) or directly support any Government contract (including
                      pending solicitations for Government contracts). Partner
                      certifies that all information and materials provided to
                      AWS in connection with this request are true, complete,
                      and accurate. Any funding Benefits issued in response to
                      this request must be used only to support the Project, and
                      any AWS Promotional Credit (“Credits”) may be redeemed
                      exclusively on the AWS Account ID you provide to AWS in
                      connection with this application (if applicable). Partner
                      may not use the funding Benefits in any way to benefit
                      Government personnel (including travel, lodging, gifts, or
                      other contributions).
                    </React.Fragment>
                  );
                }
                if (
                  (data["program"] === PROGRAM_TYPE.SMP ||
                    data["program"] === PROGRAM_TYPE.POC ||
                    data["program"] === PROGRAM_TYPE.PIF) &&
                  field.name === "wwpsConsent"
                ) {
                  field.description = (
                    <div>
                      By checking this box, you represent and warrant that you
                      have the legal authority to bind the APN Partner applying
                      for funding Benefits (“Partner”) and that Partner agrees
                      to the following:
                      <br />
                      <br />
                      Partner certifies that all information and materials
                      provided to AWS in connection with this request (such as
                      statements of work) are true, complete, and accurate. Any
                      funding Benefits must be used only to support the project
                      for which Partner is applying for funding Benefits
                      (“Project”), and any AWS Promotional Credit (“Credits”)
                      may be redeemed exclusively on the AWS Account ID you
                      provide to AWS in connection with this application.
                      Partner may not retain any surplus funding Benefits and
                      will inform AWS of any remaining unused funding Benefits
                      at Project completion. Use of all funding Benefits is
                      subject to the AWS Partner Network Terms & Conditions (
                      <a
                        href={
                          "https://aws.amazon.com/partners/terms-and-conditions/"
                        }
                      >
                        APN Terms & Conditions
                      </a>{" "}
                      )
                      <br />
                      <br />
                      Funding Benefits are intended to offset the customer’s
                      costs under the Project. Credits will offset AWS Services
                      fees, and cash funding must offset the customer’s other
                      costs, such as Partner’s professional services fees. While
                      Partner is free to determine its own pricing,
                      <b>
                        Partner may not retain or use any portion of Credits or
                        other funding as additional compensation or margin.
                        Accordingly, Partner must pass on the full value of the
                        Benefits to the customer as a discount or rebate for the
                        work performed under the Project.
                      </b>
                      <br />
                      <br />
                      If your customer is a Government customer (as defined in
                      the APN Terms & Conditions), to the maximum extent
                      permitted by applicable bidding or tendering rules,{" "}
                      <b>
                        Partner will disclose in writing to the customer that
                        AWS is sponsoring the Project by providing funding
                        (including the amount). Any Funding Benefits must be
                        identified in the Project statement of work with the
                        customer.
                      </b>{" "}
                      Upon request, and to the extent permitted by applicable
                      law or confidentiality agreement, Partner will provide to
                      AWS a copy of such disclosure (or a portion thereof), with
                      appropriate redactions of Partner’s competitively
                      sensitive information. AWS may notify or coordinate with
                      the customer regarding funding Benefits or the Project.
                      Notwithstanding any nondisclosure agreement between
                      Partner and AWS, AWS may also disclose details regarding
                      the funding Benefits to the customer or other Government
                      entities. Partner may not use the funding Benefits in any
                      way to benefit Government personnel (including travel,
                      lodging, gifts, or other contributions).
                      <br />
                      <br />
                      Partner must comply with all applicable government
                      procurement laws, rules, regulations, and contract
                      provisions pertaining to discounts and rebates, as well as
                      ethics and integrity (e.g., prohibitions against
                      gratuities, bribery, corruption, kickbacks, conflicts of
                      interest, false statements/claims, etc.). AWS recommends
                      you confirm with the customer’s contracting officer,
                      ethics official, or similar representative that Partner’s
                      use of funding Benefits is lawful, ethical, and
                      permissible, and does not create a conflict of interest
                      for Partner, AWS, or any other third parties.
                    </div>
                  );
                }

                if (
                  data["program"] === PROGRAM_TYPE.MISC &&
                  field.name === "wwpsConsent"
                ) {
                  field.description = (
                    <div>
                      By checking this box, you represent and warrant that you
                      have the legal authority to bind the APN Partner applying
                      for funding Benefits (“Partner”) and that Partner agrees
                      to the following:
                      <br />
                      <br />
                      Partner certifies that all information and materials
                      provided to AWS in connection with this request (such as
                      statements of work) are true, complete, and accurate. Any
                      funding Benefits must be used only to support the project
                      for which Partner is applying for funding Benefits
                      (“Project”), and any AWS Promotional Credit (“Credits”)
                      may be redeemed exclusively on the AWS Account ID you
                      provide to AWS in connection with this application.
                      Partner may not retain any surplus funding Benefits and
                      will inform AWS of any remaining unused funding Benefits
                      at Project completion. Use of all funding Benefits is
                      subject to the AWS Partner Network Terms & Conditions (
                      <a href="https://aws.amazon.com/partners/terms-and-conditions/">
                        APN Terms & Conditions
                      </a>{" "}
                      )
                      <br />
                      <br />
                      If this request is in connection with an opportunity or
                      project with an end customer, Partner also agrees to the
                      following:
                      <br />
                      <br />
                      Funding Benefits are intended to offset the customer’s
                      costs under the Project. Credits will offset AWS Services
                      fees, and cash funding must offset the customer’s other
                      costs, such as Partner’s professional services fees. While
                      Partner is free to determine its own pricing,
                      <b>
                        Partner may not retain or use any portion of Credits or
                        other funding as additional compensation or margin.
                        Accordingly, Partner must pass on the full value of the
                        Benefits to the customer as a discount or rebate for the
                        work performed under the Project.
                      </b>
                      <br />
                      <br />
                      If your customer is a Government customer (as defined in
                      the APN Terms & Conditions), to the maximum extent
                      permitted by applicable bidding or tendering rules,{" "}
                      <b>
                        Partner will disclose in writing to the customer that
                        AWS is sponsoring the Project by providing funding
                        (including the amount). Any Funding Benefits must be
                        identified in the Project statement of work with the
                        customer.
                      </b>{" "}
                      Upon request, and to the extent permitted by applicable
                      law or confidentiality agreement, Partner will provide to
                      AWS a copy of such disclosure (or a portion thereof), with
                      appropriate redactions of Partner’s competitively
                      sensitive information. AWS may notify or coordinate with
                      the customer regarding funding Benefits or the Project.
                      Notwithstanding any nondisclosure agreement between
                      Partner and AWS, AWS may also disclose details regarding
                      the funding Benefits to the customer or other Government
                      entities. Partner may not use the funding Benefits in any
                      way to benefit Government personnel (including travel,
                      lodging, gifts, or other contributions).
                      <br />
                      <br />
                      Partner must comply with all applicable government
                      procurement laws, rules, regulations, and contract
                      provisions pertaining to discounts and rebates, as well as
                      ethics and integrity (e.g., prohibitions against
                      gratuities, bribery, corruption, kickbacks, conflicts of
                      interest, false statements/claims, etc.). AWS recommends
                      you confirm with the customer’s contracting officer,
                      ethics official, or similar representative that Partner’s
                      use of funding Benefits is lawful, ethical, and
                      permissible, and does not create a conflict of interest
                      for Partner, AWS, or any other third parties.
                    </div>
                  );
                }

                const inputProps = InputPropsBuilder({
                  data,
                  errors,
                  field,
                  options,
                  fieldToIdMap,
                  disableFactory: DisableFactory,
                  valueFactory: ValueFactory,
                });

                return (
                  <InputFactory
                    key={index}
                    dataInput={dataInput}
                    fieldToIdMap={fieldToIdMap}
                    updateErrors={updateErrors}
                    validators={validators}
                    showHelperPanel={showHelperPanel}
                    fileuploadProp={FileUploadTriggers}
                    {...inputProps}
                  />
                );
              }
            )}
        </React.Fragment>
      </Section>
    );
  };

  const section = isLoading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Section header={<Header variant="h2">{stepTitle}</Header>}>
        <React.Fragment>
          {fieldTemplate &&
            fieldTemplate[stepTemplate.name] &&
            fieldTemplate[stepTemplate.name].fields.map((field, index) => {
              const inputProps = InputPropsBuilder({
                data,
                errors,
                field,
                options,
                fieldToIdMap,
                disableFactory: DisableFactory,
                valueFactory: ValueFactory,
              });

              return (
                <InputFactory
                  key={index}
                  dataInput={dataInput}
                  fieldToIdMap={fieldToIdMap}
                  updateErrors={updateErrors}
                  validators={validators}
                  showHelperPanel={showHelperPanel}
                  fileuploadProp={FileUploadTriggers}
                  {...inputProps}
                />
              );
            })}
        </React.Fragment>
      </Section>
      <br />
      {fieldTemplate[SECTION_NAME.PUBLIC_SECTOR_DETAILS] ? <WwpsSection /> : ""}
    </React.Fragment>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

ProjectInformationSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  showHelperPanel: PropTypes.func,
};

export default React.memo(ProjectInformationSectionContainer);
