import React, { useEffect, useRef, useState } from "react";

import { SECTION, SECTION_NAME } from "../../util/constants/section";
import {
  ColumnLayout,
  ExpandableSection,
  Tabs,
} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import PropTypes from "prop-types";
import { useHistory, useParams, useLocation } from "react-router-dom";

import { createValueField } from "./CreateValueField";
import { CashClaimReviewAttachments } from "./CashClaim/CashClaimReviewAttachments";

import "./style.css";
import { uiToApiMap } from "../../util/adapter/toUi/adapter";
import { isFeatureEnabled } from "../../util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../util/constants/featureFlagValues";

export const CashRequestReviewSectionContainer = ({
  fundingData,
  index,
  setNotificationsItems,
  valueFactory,
  filterFields,
  schema,
  getFundRequestAttachments,
  getDownloadUrl,
  CashClaimReviewSectionContainer,
  baseUrl,
  showHeader,
}) => {
  const [isSCAEnabled, setIsSCAEnabled] = React.useState(false);
  React.useEffect(async () => {
    setIsSCAEnabled(
      await isFeatureEnabled(FEATURE_FLAG_ENUMS.SCA_FUNDING_ENABLED)
    );
  }, []);
  const history = useHistory();
  const cashClaimRef = useRef(null);
  const { hash } = useLocation();
  const urlCashClaimId = hash.substring(1);
  const [activeTabId, setActiveTabId] = useState(urlCashClaimId);
  const { id } = useParams();
  const fields = [];
  const [estimatedRequestedAmountUsd, setEstimatedRequestedAmountUsd] =
    useState(false);
  const cashRequestData = fundingData["currencyConversionRateToUsd"]
    ? {
        ...fundingData[uiToApiMap[SECTION_NAME.CASH_REQUEST_INFORMATION]],
        currencyConversionRateToUsd: parseFloat(
          fundingData["currencyConversionRateToUsd"]
        ).toFixed(2),
      }
    : fundingData[uiToApiMap[SECTION_NAME.CASH_REQUEST_INFORMATION]];

  if (fundingData["estimatedRequestedAmountUsd"]) {
    cashRequestData.estimatedRequestedAmountUsd =
      fundingData["estimatedRequestedAmountUsd"];
  }

  const cashRequestFilteredFields = filterFields({
    section: schema[SECTION.CASH_REQUEST_INFORMATION],
    program: fundingData.program,
  });

  useEffect(async () => {
    const estimatedRequestedAmountUsdFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.ESTIMATED_REQUESTED_AMOUNT_USD
    );
    setEstimatedRequestedAmountUsd(estimatedRequestedAmountUsdFlag);
  }, []);

  cashRequestFilteredFields.map((field) => {
    if (
      !estimatedRequestedAmountUsd &&
      field.name === "estimatedRequestedAmountUsdFlag"
    ) {
      return;
    }
    if (!isSCAEnabled && field.name === "strategicCollaborationAgreementCash") {
      // sca link disable
      return;
    }
    fields.push(
      createValueField({
        field,
        value: valueFactory({
          apiKey: "purchaseOrder",
          sectionTitle: SECTION.CASH_REQUEST_INFORMATION,
          name: field.name,
          data: cashRequestData,
          field,
        }),
      })
    );
  });

  const cashClaims = [];
  let urlCashClaimIdValid = false;
  if (fundingData["cashRequest"] && fundingData["cashRequest"]["cashClaims"]) {
    const cashClaimIds = Object.keys(fundingData["cashRequest"]["cashClaims"]);

    cashClaimIds.map((cashClaimId) => {
      // Plans
      const filteredPlanFields = filterFields({
        section: schema[SECTION.CASH_CLAIM_PLANS],
        program: fundingData.program,
      });

      const planFields = [];

      filteredPlanFields.map((field) =>
        planFields.push(
          createValueField({
            field,
            value: valueFactory({
              apiKey: "plan",
              sectionTitle: SECTION.CASH_CLAIM_PLANS,
              name: field.name,
              data: fundingData["cashRequest"]["cashClaims"][cashClaimId],
              field,
            }),
          })
        )
      );

      // Actuals
      const filteredActualFields = filterFields({
        section: schema[SECTION.CASH_CLAIM_ACTUALS],
        program: fundingData.program,
      });

      const actualFields = [];

      filteredActualFields.map((field) =>
        actualFields.push(
          createValueField({
            field,
            value: valueFactory({
              apiKey: "actual",
              sectionTitle: SECTION.CASH_CLAIM_ACTUALS,
              name: field.name,
              data: fundingData["cashRequest"]["cashClaims"][cashClaimId],
              field,
            }),
          })
        )
      );

      // Invoice
      const filteredInvoiceFields = filterFields({
        section: schema[SECTION.CASH_CLAIM_INVOICE_PAYMENT],
        program: fundingData.program,
      });

      const invoiceFields = [];

      filteredInvoiceFields.map((field) =>
        invoiceFields.push(
          createValueField({
            field,
            value: valueFactory({
              apiKey: "invoice",
              sectionTitle: SECTION.CASH_CLAIM_INVOICE_PAYMENT,
              name: field.name,
              data: fundingData["cashRequest"]["cashClaims"][cashClaimId],
              field,
            }),
          })
        )
      );

      const CashClaim = (
        <CashClaimReviewSectionContainer
          history={history}
          fundRequestId={id}
          fundingData={fundingData}
          cashClaimId={cashClaimId}
          planFields={planFields}
          actualFields={actualFields}
          invoiceFields={invoiceFields}
          setNotificationsItems={setNotificationsItems}
          cashClaimAttachmentComponent={
            <CashClaimReviewAttachments
              cashClaimId={cashClaimId}
              setNotificationsItems={setNotificationsItems}
              getFundRequestAttachments={getFundRequestAttachments}
              getDownloadUrl={getDownloadUrl}
            />
          }
        />
      );
      cashClaims.push({
        id: `${cashClaimId}`,
        label: `${cashClaimId}`,
        content: CashClaim,
      });

      if (!urlCashClaimIdValid) {
        urlCashClaimIdValid = urlCashClaimId === cashClaimId;
      }
    });
  }

  const setActiveCashClaimTab = () => {
    if (urlCashClaimIdValid) {
      cashClaimRef.current?.scrollIntoView();
    } else {
      setActiveTabId(cashClaims[0].id);
    }
  };

  useEffect(() => {
    if (cashClaims.length > 0) {
      setActiveCashClaimTab();
    }
  }, []);

  return (
    <React.Fragment key={index}>
      <ExpandableSection
        variant="container"
        defaultExpanded={true}
        header={
          <Header
            actions={
              showHeader ? (
                <Button
                  onClick={() =>
                    history.push(`${baseUrl}/${id}/cashRequestInformation/edit`)
                  }
                >
                  Edit
                </Button>
              ) : (
                ""
              )
            }
          >
            {SECTION.CASH_REQUEST_INFORMATION}
          </Header>
        }
      >
        <ColumnLayout columns={4} variant="text-grid">
          {fields}
        </ColumnLayout>
      </ExpandableSection>

      <br ref={cashClaimRef} />
      {cashClaims.length > 0 ? (
        <Tabs
          tabs={cashClaims}
          onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
          activeTabId={activeTabId}
        />
      ) : (
        ""
      )}

      <br />
    </React.Fragment>
  );
};

CashRequestReviewSectionContainer.propTypes = {
  fundingData: PropTypes.object,
  index: PropTypes.number,
  step: PropTypes.array,
  template: PropTypes.object,
  setNotificationsItems: PropTypes.func,
  valueFactory: PropTypes.func,
  filterFields: PropTypes.func,
  schema: PropTypes.object,
  getFundRequestAttachments: PropTypes.func,
  getDownloadUrl: PropTypes.func,
  CashClaimReviewSectionContainer: PropTypes.func,
  baseUrl: PropTypes.string,
  showHeader: PropTypes.bool,
};
