import React, { useEffect, useState } from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";
import { useOptionsContext } from "../../../util/context/OptionsContext";
import {
  schema,
  filterFields,
  getInternalTemplate,
} from "../../../config/schema";
import { getFundRequest } from "../../../util/services/data/InternalDataService";
import { InputFactory } from "../../../../shared/components/FundRequest/InputFactory";
import Loading from "../../../../shared/components/common/Loading";
import { SpaceBetween } from "@amzn/awsui-components-react";
import Section from "../../../../shared/components/FundRequest/StepsContainer/Section";
import { InputPropsBuilder } from "../../../../shared/components/FundRequest/InputPropsBuilder";
import { DisableFactory } from "./DisableFactory";
import { ValueFactory } from "./ValueFactory";
import useFields from "../../../../shared/util/hooks/useFields";
import { SECTION_NAME } from "../../../../shared/util/constants/section";
import { FileUploadTriggers } from "../../../util/services/data/dataFactory";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import { PAGES } from "../../../util/constants/pages";

const CashRequestEditSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  page,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];
  const [{ additionalSections, fieldTemplate, isLoading }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getInternalTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  const NumberOfClaims = () => {
    const [numOfClaims] = fieldTemplate[stepTemplate.name].fields.filter(
      (field) => field.name === "numberOfClaims"
    );
    return numOfClaims ? (
      <InputFactory
        componentType={numOfClaims.component}
        id={numOfClaims.id}
        value={additionalSections.length}
        dataInput={dataInput}
        name={numOfClaims.name}
        disabled={true}
        label={numOfClaims.label}
        placeholder={numOfClaims.placeholder}
        showHelperPanel={showHelperPanel}
        fileuploadProp={FileUploadTriggers}
      />
    ) : (
      <></>
    );
  };

  const [attachmentFeatureFlag, setAttachmentFeatureFlag] = useState(false);
  const [claimAmountUpdateEnabled, setClaimAmountUpdateEnabled] =
    useState(false);
  const [isSCAEnabled, setIsSCAEnabled] = useState(false);

  useEffect(async () => {
    const attachmentFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.CASH_CLAIM_ATTACHMENT_INTERNAL
    );
    setAttachmentFeatureFlag(attachmentFlag);
    const claimAmountUpdateFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.UPDATE_CLAIM_AMOUNT_INTERNAL_ENABLE
    );
    setClaimAmountUpdateEnabled(claimAmountUpdateFlag);

    const scaFlag = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.SCA_FUNDING_ENABLED
    );
    setIsSCAEnabled(scaFlag);
  });
  let updatedFieldTemplate = JSON.parse(JSON.stringify(fieldTemplate));
  if (
    claimAmountUpdateEnabled &&
    updatedFieldTemplate &&
    updatedFieldTemplate[SECTION_NAME.CASH_CLAIM_ACTUALS]
  ) {
    const claimAmountIndex = updatedFieldTemplate[
      SECTION_NAME.CASH_CLAIM_ACTUALS
    ]["fields"].findIndex((field) => field.name === "claimAmount");
    if (claimAmountIndex !== -1) {
      updatedFieldTemplate[SECTION_NAME.CASH_CLAIM_ACTUALS]["fields"][
        claimAmountIndex
      ]["edit"] = [PAGES.CASH_CLAIM_APPROVAL];
    }
  }

  const section = isLoading ? (
    <Loading />
  ) : (
    <SpaceBetween>
      <Section header={<Header variant="h2">{stepTitle}</Header>}>
        <React.Fragment>
          {updatedFieldTemplate &&
            updatedFieldTemplate[stepTemplate.name] &&
            updatedFieldTemplate[stepTemplate.name].fields
              .filter((field) => field.name !== "numberOfClaims")
              .filter(
                (field) =>
                  !(
                    field.name === "strategicCollaborationAgreementCash" &&
                    !isSCAEnabled
                  )
              ) // sca link disable
              .map((field, index) => {
                const inputProps = InputPropsBuilder({
                  data,
                  errors,
                  field,
                  options,
                  fieldToIdMap,
                  page,
                  sectionName: SECTION_NAME.CASH_REQUEST_INFORMATION,
                  disableFactory: DisableFactory,
                  valueFactory: ValueFactory,
                });

                FileUploadTriggers.deleteFile = attachmentFeatureFlag
                  ? FileUploadTriggers.deleteFile
                  : null;
                return (
                  <InputFactory
                    key={index}
                    dataInput={dataInput}
                    fieldToIdMap={fieldToIdMap}
                    updateErrors={updateErrors}
                    validators={validators}
                    showHelperPanel={showHelperPanel}
                    fileuploadProp={FileUploadTriggers}
                    {...inputProps}
                  />
                );
              })}
          <NumberOfClaims />
        </React.Fragment>
      </Section>
      <br />
      {additionalSections.map((sectionIndex) => {
        const templateName =
          sectionIndex > 1
            ? `cashClaimPlans-${sectionIndex}`
            : "cashClaimPlans";
        return (
          <React.Fragment key={sectionIndex}>
            <Section
              header={
                <SpaceBetween size="l">
                  <Header variant="h2">
                    {`${fieldTemplate[templateName].title} ${
                      sectionIndex > 1 ? sectionIndex : ""
                    }`}
                  </Header>
                </SpaceBetween>
              }
            >
              <React.Fragment>
                {fieldTemplate &&
                  fieldTemplate[templateName] &&
                  fieldTemplate[templateName].fields.map((field, index) => {
                    const inputProps = InputPropsBuilder({
                      data,
                      errors,
                      field,
                      options,
                      index: sectionIndex,
                      fieldToIdMap,
                      page,
                      disableFactory: DisableFactory,
                      valueFactory: ValueFactory,
                    });

                    return (
                      <InputFactory
                        key={index}
                        dataInput={dataInput}
                        fieldToIdMap={fieldToIdMap}
                        updateErrors={updateErrors}
                        validators={validators}
                        showHelperPanel={showHelperPanel}
                        {...inputProps}
                      />
                    );
                  })}
              </React.Fragment>
            </Section>
            <br />
          </React.Fragment>
        );
      })}
    </SpaceBetween>
  );
  return <SpaceBetween>{section}</SpaceBetween>;
};

CashRequestEditSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  page: PropTypes.string,
  showHelperPanel: PropTypes.func,
};

export default React.memo(CashRequestEditSectionContainer);
