import { COMPONENT_TYPE } from "../../constants/componentType";
import { uiToApiMap } from "./adapter";

const multiSelectUiAdapter = ({ data, fieldName, apiKey }) => {
  let value = [];

  if (data) {
    const dataKeys = Object.keys(data);
    const apiSectionName = uiToApiMap[apiKey];

    if (dataKeys.includes(fieldName)) {
      value = data[fieldName];
    } else if (dataKeys.includes(apiSectionName)) {
      value = data[apiSectionName][fieldName];
    }

    if (value && value.length > 0) {
      value = value.map((selectedValue) => ({
        label: selectedValue,
        value: selectedValue,
      }));
    }
  }
  return value;
};

const dateUiAdapter = ({ data, fieldName, apiKey }) => {
  let value = "";
  if (data) {
    const dataKeys = Object.keys(data);
    const apiSectionName = uiToApiMap[apiKey];

    if (fieldName === "extendedStartDate") {
      value = data["projectExtension"][fieldName];
    } else if (fieldName === "issuranceDate") {
      value = data["cashRequest"]["purchaseOrder"]["issuranceDate"];
    } else if (fieldName === "extendedEndDate") {
      value = data["projectExtension"][fieldName];
    } else if (dataKeys.includes(fieldName)) {
      value = data[fieldName];
    } else if (dataKeys.includes(apiSectionName)) {
      value = data[apiSectionName][fieldName];
    }

    if (value && Object.keys(value).includes("timestamp")) {
      value = new Date(value.timestamp).toISOString().split("T")[0];
    }
  }

  return value;
};

const defaultUiAdapter = ({ data, fieldName, apiKey, type }) => {
  let value = "";
  if (data) {
    const dataKeys = Object.keys(data);
    const apiSectionName = uiToApiMap[apiKey];
    if (
      apiSectionName === "migrationEngagements" &&
      data[apiSectionName].length !== 0
    ) {
      value = data[apiSectionName][0][fieldName];
      if (fieldName === "migrationArr" && value) {
        value = parseFloat(value.units).toLocaleString("en-US");
      }
    } else if (fieldName === "poCurrency") {
      value = data["cashRequest"]["purchaseOrder"]["currency"];
    } else if (fieldName === "requestNumber") {
      value = data["cashRequest"]["purchaseOrder"]["requestNumber"];
    } else if (fieldName === "issured") {
      value = data["cashRequest"]["purchaseOrder"]["issured"];
    } else if (fieldName === "number") {
      value = data["cashRequest"]["purchaseOrder"]["number"];
    } else if (apiSectionName === "cashRequest" && fieldName === "amount") {
      value = data["cashRequest"]["purchaseOrder"]["amount"];
    } else if (apiSectionName === "cashRequest" && fieldName === "amountUsd") {
      value = data["cashRequest"]["purchaseOrder"]["amountUsd"];
    } else if (fieldName === "totalMdfCashAmount") {
      value = data["cashRequest"]["totalMdfCashAmount"];
    } else if (fieldName === "fundRequestOwner") {
      value = data["owner"]["userName"];
    } else if (fieldName === "extendedReason") {
      value = data["projectExtension"][fieldName];
    } else if (fieldName === "requestedCreditAmountEditable") {
      value = data["creditRequest"]["requestedCreditAmount"];
    } else if (fieldName === "strategicCollaborationAgreementCash") {
      value = data?.cashRequest?.cashBudgetInfo?.fundingContractName;
    } else if (fieldName === "strategicCollaborationAgreementCredit") {
      value = data?.creditRequest?.creditBudgetInfo?.fundingContractName;
    } else if (dataKeys.includes(fieldName)) {
      value = data[fieldName];
    } else if (dataKeys.includes(apiSectionName)) {
      value = data[apiSectionName][fieldName];
    } else if (dataKeys.includes(apiKey) && data[apiKey]) {
      value = data[apiKey][fieldName];
    }

    if (value && type === "money" && Object.keys(value).includes("units")) {
      value = parseFloat(value.units).toLocaleString("en-US");
    }

    if (value && !isNaN(value)) {
      value = value.toString();
    }
  }

  return value;
};

export const UiValueAdapterFactory = (componentType) => {
  switch (componentType) {
    case COMPONENT_TYPE.MULTI_SELECT:
      return multiSelectUiAdapter;
    case COMPONENT_TYPE.DATEPICKER:
      return dateUiAdapter;
    default:
      return defaultUiAdapter;
  }
};
