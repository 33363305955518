import React, { ReactElement, useContext } from "react";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useHistory } from "react-router-dom";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ReadOnlyCashClaim from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Review/sections/claims/ReadOnlyCashClaim";
import CashClaimActionsHeader from "external/programs/migration-acceleration-program/2024/fund-request/components/details/FundRequestDetailsContainer/header/claims/CashClaimActionsHeader";
import { getFundRequestId } from "external/util/common/helper";
import CashClaimPlan from "external/programs/migration-acceleration-program/2024/fund-request/components/shared/common/claims/CashClaimPlan";
import { claimStagesKeys } from "shared/programs/migration-acceleration-program/2024/fund-request/util/claims";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { HINDER_FR_MESSAGE } from "external/components/FundRequest/Review/Sections/CashClaimAction";
import { nanoid } from "nanoid";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";

const CashClaimsContainer = ({
  data,
}: {
  data: IGenericObject;
  actions?: ReactElement;
}) => {
  const history = useHistory();
  const { setNotifications } = useContext(NotificationsContext);
  const hinderFcSubmission = useFeatureFlag(
    FEATURE_FLAG_ENUMS.HINDER_FC_SUBMISSION
  );
  const claims: Array<IGenericObject> = data?.claims;

  if (!claims || claims.length === 0) return <></>;

  const submitClaim = (claimId: string) => {
    if (hinderFcSubmission) {
      setNotifications([
        {
          type: "error",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => setNotifications([]),
          header: "Error",
          content: HINDER_FR_MESSAGE,
          id: nanoid(10),
        },
      ]);
    } else {
      history.push(`/fund-requests/${getFundRequestId()}/${claimId}/new`);
    }
  };

  return (
    <SpaceBetween size="l">
      {claims.map((claim, index) => {
        const claimId = claim.claimId;
        const currentStage = claim?.claimStage;
        const currentStatus = claim?.claimStatus;
        const priority = claim?.claimPriority;

        const headerTitle = `Cash claim ${index + 1}`;
        const key = `${claimId}-${index}`;

        if (!currentStage || currentStage === claimStagesKeys.partner) {
          return (
            <CashClaimPlan
              key={key}
              headerTitle={headerTitle}
              data={data}
              claim={claim}
              actions={
                <CashClaimActionsHeader
                  data={data}
                  claimId={claimId}
                  priority={priority}
                  claimStatus={currentStatus}
                  onClick={() => submitClaim(claimId)}
                />
              }
            />
          );
        }
        return (
          <ReadOnlyCashClaim
            key={key}
            headerTitle={headerTitle}
            data={data}
            claim={claim}
            actions={
              <CashClaimActionsHeader
                data={data}
                claimId={claimId}
                claimStatus={currentStatus}
                priority={priority}
                onClick={() => submitClaim(claimId)}
              />
            }
          />
        );
      })}
    </SpaceBetween>
  );
};

export default CashClaimsContainer;
