import { SECTION_NAME } from "../../../../../../shared/util/constants/section";
import { getUsername } from "../../../../../util/services/auth/AuthService";
import { generateMultiSectionKey } from "../../../../../../shared/util/common/generateMultiSectionKey";

//TODO: This needs to be unit tested...
const defaultValue = ({ data, id }) => {
  return data[id];
};

const additionalIncentivesValue = ({ data, fieldToIdMap, index }) => {
  const sectionKey = generateMultiSectionKey({
    index,
    sectionName: SECTION_NAME.MIGRATION_WORKLOADS,
  });
  const connectARRThreshold = 500000;
  const annualRunRateUnits = data && data.annualRunRateUnits;
  const specializedWorkloadId = fieldToIdMap[sectionKey]["specializedWorkload"];
  const postMigrationArrId =
    fieldToIdMap[sectionKey]["postMigrationAnnualRunRate"];

  const specializedWorkload = (data && data[specializedWorkloadId]) || "";

  let postMigrationArr = data && data[postMigrationArrId];

  if (typeof postMigrationArr === "string") {
    postMigrationArr =
      parseFloat(data[postMigrationArrId].replace(/,/g, "")) || 0;
  }

  //TODO: Define the cases as constants.
  switch (specializedWorkload) {
    case "Windows":
      return (postMigrationArr * 0.05).toLocaleString("en-US");
    case "SAP":
      return (postMigrationArr * 0.5).toLocaleString("en-US");
    case "DB/Analytics":
      return (postMigrationArr * 0.1).toLocaleString("en-US");
    case "Commercial DB/Analytics":
      return (postMigrationArr * 0.25).toLocaleString("en-US");
    case "Oracle":
      return (postMigrationArr * 0.5).toLocaleString("en-US");
    case "Non-Commercial Database":
      return (postMigrationArr * 0.1).toLocaleString("en-US");
    case "Non-Commercial Analytics":
      return (postMigrationArr * 0.1).toLocaleString("en-US");
    case "Commercial Database":
      return (postMigrationArr * 0.25).toLocaleString("en-US");
    case "Commercial Analytics":
      return (postMigrationArr * 0.25).toLocaleString("en-US");
    case "Connect":
      if (annualRunRateUnits >= connectARRThreshold)
        return (postMigrationArr * 0.25).toLocaleString("en-US");
      else return 0;
    default: //For the remaining specializedWorkloads the additionalIncentivesValue is 0
      return 0;
  }
};

const fundRequestOwnerValue = () => {
  return getUsername();
};

const requestedCreditAmountValue = ({ data, fieldToIdMap }) => {
  const numberOfCodesId =
    fieldToIdMap[SECTION_NAME.CREDIT_REQUEST_DETAILS]["numberOfCodes"];
  const valuePerCodeId =
    fieldToIdMap[SECTION_NAME.CREDIT_REQUEST_DETAILS]["valuePerCode"];
  const numberOfCodes = (data && data[numberOfCodesId]) || 0;
  const valuePerCode =
    (data &&
      data[valuePerCodeId] &&
      parseFloat(data[valuePerCodeId].replace(/,/g, ""))) ||
    0;

  const requestedCreditAmount = numberOfCodes * valuePerCode;
  return `${requestedCreditAmount.toLocaleString("en-US")}`;
};

const awsAccountIdValue = ({ data, fieldToIdMap, id, sectionName }) => {
  let value = data[id];
  if (sectionName === SECTION_NAME.CREDIT_REQUEST_DETAILS) {
    const awsAccountId =
      fieldToIdMap[SECTION_NAME.PROJECT_INFORMATION]["awsAccountId"];
    value = data[awsAccountId];
  }
  return value;
};

const dueDateValue = ({ data, fieldToIdMap }) => {
  const plannedEndDateId =
    fieldToIdMap[SECTION_NAME.PROJECT_INFORMATION]["plannedEndDate"];
  let plannedEndDate = data[plannedEndDateId];
  if (
    typeof plannedEndDate === "object" &&
    Object.keys(plannedEndDate).includes("timestamp")
  ) {
    plannedEndDate = new Date(data[plannedEndDateId].timestamp);
  } else {
    plannedEndDate = new Date(data[plannedEndDateId]);
  }

  const yearEndCutOff = new Date(plannedEndDate.getFullYear(), 11, 15);

  let expiration = yearEndCutOff;

  if (plannedEndDate < yearEndCutOff) {
    expiration = new Date(
      plannedEndDate.setDate(plannedEndDate.getDate() + 30)
    );
  }

  return expiration.toISOString().split("T")[0];
};

const mdfFundingPercentageValue = ({ data, fieldToIdMap, index }) => {
  const sectionKey = generateMultiSectionKey({
    index,
    sectionName: SECTION_NAME.CASH_CLAIM_PLANS,
  });

  const totalCostOfActivityId =
    fieldToIdMap[sectionKey]["totalCostOfMdfActivity"];
  const requestedMdfCashFundingId =
    fieldToIdMap[sectionKey]["requestedMdfCashFunding"];

  const totalCostOfActivity =
    (data[totalCostOfActivityId] &&
      parseFloat(data[totalCostOfActivityId].replace(/,/g, ""))) ||
    0;
  const requestMdfCashFunding =
    (data[requestedMdfCashFundingId] &&
      parseFloat(data[requestedMdfCashFundingId].replace(/,/g, ""))) ||
    0;

  const percentageValue = Math.floor(
    (requestMdfCashFunding / totalCostOfActivity) * 100
  );

  return `${
    isNaN(percentageValue) && isFinite(percentageValue) ? 0 : percentageValue
  }%`;
};

const totalMdfCashAmountValue = ({ data, fieldToIdMap }) => {
  const cashClaimKeys = Object.keys(fieldToIdMap).filter((sectionName) =>
    sectionName.includes(SECTION_NAME.CASH_CLAIM_PLANS)
  );

  let total = 0;

  for (let cashClaimKey of cashClaimKeys) {
    const cashFundingAmountId =
      fieldToIdMap[cashClaimKey]["requestedMdfCashFunding"];
    const cashFundingAmount =
      (data[cashFundingAmountId] &&
        parseFloat(data[cashFundingAmountId].replace(/,/g, ""))) ||
      0;
    total += parseFloat(cashFundingAmount);
  }

  return total;
};

const currencyValue = ({ data, fieldToIdMap, id, sectionName }) => {
  let value = defaultValue({ data, id });
  if (sectionName !== SECTION_NAME.CASH_REQUEST_INFORMATION) {
    const currentCurrencyId =
      fieldToIdMap[SECTION_NAME.CASH_REQUEST_INFORMATION]["currency"];
    value = data[currentCurrencyId];
  }
  return value;
};

const executedCountryValue = ({ data, fieldToIdMap, id }) => {
  let value = defaultValue({ data, id });

  if (value !== "US") {
    const executedStateId =
      fieldToIdMap[SECTION_NAME.PROJECT_INFORMATION]["executedState"];
    data[executedStateId] = [];
  }
  return value;
};

export const ValueFactory = (name) => {
  switch (name) {
    case "fundRequestOwner":
      return fundRequestOwnerValue;
    case "additionalIncentive":
      return additionalIncentivesValue;
    case "requestedCreditAmount":
      return requestedCreditAmountValue;
    case "awsAccountId":
      return awsAccountIdValue;
    case "dueDate":
      return dueDateValue;
    case "mdfFundingPercentage":
      return mdfFundingPercentageValue;
    case "totalMdfCashAmount":
      return totalMdfCashAmountValue;
    case "currencyValue":
      return currencyValue;
    case "executedCountry":
      return executedCountryValue;
    default:
      return defaultValue;
  }
};
