import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";

import { filterFields, getTemplate, schema } from "../../../../config/schema";
import useFields from "../../../../../shared/util/hooks/useFields";
import Loading from "../../../../../shared/components/common/Loading";
import { useOptionsContext } from "../../../../util/context/OptionsContext";
import Section from "../../../../../shared/components/FundRequest/StepsContainer/Section";
import { DisableFactory } from "../Inputs/PropBuilder/DisableFactory";
import { ValueFactory } from "../Inputs/PropBuilder/ValueFactory";
import { InputPropsBuilder } from "../../../../../shared/components/FundRequest/InputPropsBuilder";
import { InputFactory } from "../../../../../shared/components/FundRequest/InputFactory";
import { getFundRequest } from "../../../../util/services/data/FundRequestService";
import { FileUploadTriggers } from "../../../../util/services/data/dataFactory";
import { smpMapLite } from "../../../../../shared/util/constants/smpMapLite";
import { PROGRAM_TYPE } from "../../../../../shared/util/constants/programType";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

const AttachmentsSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  setNotificationItems,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];

  const [{ fieldTemplate, isLoading }] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  if (smpMapLite(data) && Object.keys(fieldTemplate).length !== 0) {
    fieldTemplate.fundRequestInformation.fields.forEach((field) => {
      if (field.name === "awsCalculatorUrl") {
        field.required = false;
      }
    });
  }

  const [isSCAFlagEnabled, setIsSCAFlagEnabled] = React.useState(false);

  // Check feature flag
  React.useEffect(async () => {
    if (data["program"] === PROGRAM_TYPE.MDF) {
      try {
        setIsSCAFlagEnabled(
          await isFeatureEnabled(FEATURE_FLAG_ENUMS.SCA_FUNDING_ENABLED)
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  const section = isLoading ? (
    <Loading />
  ) : (
    <Section
      header={
        <Header
          variant="h2"
          description={
            data["program"] === PROGRAM_TYPE.MDF && isSCAFlagEnabled ? (
              <React.Fragment>
                By proceeding forward, you represent and warrant that you have
                the legal authority to bind the APN Partner applying for funding
                Benefits (“Partner”) and that Partner agrees to the following:
                <br />
                <br />
                Partner certifies that the project for which Partner is applying
                for funding Benefits (“Project”) meets the requirements for the
                applicable project type under the current APN Funding Guide, and
                does not relate to any individual Government customer (as
                defined in the{" "}
                <a href="https://aws.amazon.com/partners/terms-and-conditions/">
                  APN Terms & Conditions
                </a>{" "}
                ) or directly support any Government contract (including pending
                solicitations for Government contracts). Partner certifies that
                all information and materials provided to AWS in connection with
                this request are true, complete, and accurate. Any funding
                Benefits issued in response to this request must be used only to
                support the Project, and any AWS Promotional Credit (“Credits”)
                may be redeemed exclusively on the AWS Account ID you provide to
                AWS in connection with this application (if applicable). Partner
                may not use the funding Benefits in any way to benefit
                Government personnel (including travel, lodging, gifts, or other
                contributions).
              </React.Fragment>
            ) : (
              ""
            )
          }
        >
          {stepTitle}
        </Header>
      }
    >
      <React.Fragment>
        {fieldTemplate &&
          fieldTemplate[stepTemplate.name] &&
          fieldTemplate[stepTemplate.name].fields.map((field, index) => {
            const inputProps = InputPropsBuilder({
              data,
              errors,
              field,
              options,
              fieldToIdMap,
              sectionName: stepTemplate.name,
              disableFactory: DisableFactory,
              valueFactory: ValueFactory,
            });

            return (
              <InputFactory
                key={index}
                dataInput={dataInput}
                fieldToIdMap={fieldToIdMap}
                updateErrors={updateErrors}
                validators={validators}
                setNotificationItems={setNotificationItems}
                showHelperPanel={showHelperPanel}
                fileuploadProp={FileUploadTriggers}
                {...inputProps}
              />
            );
          })}
      </React.Fragment>
    </Section>
  );
  return <React.Fragment>{section}</React.Fragment>;
};

AttachmentsSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  setNotificationItems: PropTypes.func,
  showHelperPanel: PropTypes.func,
};

export default React.memo(AttachmentsSectionContainer);
