import React from "react";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { getBudgets } from "external/util/services/data/FundRequestService.js";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "shared/util/services/data/DataService";
import { PROGRAM_TYPE } from "shared/util/constants/programType";
import { PropTypes } from "prop-types";

export const SCATableSelector = ({ data, fieldTemplate, fundingTypes }) => {
  // State variables for SCA
  const [isSCAFlagEnabled, setIsSCAFlagEnabled] = React.useState(false);
  const [isSCAFlagLoading, setIsSCAFlagLoading] = React.useState(true);
  const [budgets, setBudgets] = React.useState([]);
  const [isSCALoading, setIsSCALoading] = React.useState(true);
  const [isSCAPartner, setIsSCAPartner] = React.useState(false);

  // For getBudgets query to fetch corresponding SCA budgets (data["program"] can be Sandbox, MDF etc)
  const fundingTemplates = [data["program"]];
  const budgetRecordTypes = ["SCA"];

  // Functions to verify if table should be enabled
  // Enables table if the partner is an SCA partner
  const getIfSCAPartner = () => {
    return !isSCALoading && isSCAFlagEnabled && isSCAPartner;
  };
  // Disables the table if the fund request is WWPS or PIF or MAP
  const getIfProgramTypeAllowsSCA = () => {
    return (
      !fieldTemplate.publicSectorDetails && // WWPS
      !PROGRAM_TYPE.PIF.includes(data["program"]) && // PIF
      !PROGRAM_TYPE.SMP.includes(data["program"]) // MAP
    );
  };

  // Check feature flag
  React.useEffect(async () => {
    try {
      setIsSCAFlagEnabled(
        await isFeatureEnabled(FEATURE_FLAG_ENUMS.SCA_FUNDING_ENABLED)
      );
      setIsSCAFlagLoading(false);
    } catch (e) {
      console.error(e);
      setIsSCALoading(false);
      setIsSCAFlagLoading(false);
    }
  }, []);

  // Get budgets if SCA enabled
  React.useEffect(async () => {
    if (!isSCAFlagLoading) {
      // Flag is done loading
      try {
        if (isSCAFlagEnabled) {
          const response = await retryApiCall({
            callApi: getBudgets({
              fundingTemplates,
              fundingTypes,
              budgetRecordTypes,
            }),
          });
          if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
            setIsSCALoading(false);
          } else if (
            response &&
            response.wallets &&
            Object.entries(response.wallets).length > 0
          ) {
            setBudgets(response.wallets);
            setIsSCAPartner(true);
            setIsSCALoading(false);
          } else {
            setIsSCALoading(false);
          }
        } else {
          setIsSCALoading(false);
        }
      } catch (e) {
        console.error(e);
        setIsSCALoading(false);
      }
    }
  }, [isSCAFlagLoading, isSCAFlagEnabled]);

  // Determine if the table should be enabled
  const enableSCA = getIfSCAPartner() && getIfProgramTypeAllowsSCA();

  // Returns current loading state, enable state, and budget list
  return { isSCALoading, enableSCA, budgets };
};

SCATableSelector.propTypes = {
  data: PropTypes.object,
  fieldTemplate: PropTypes.object,
  fundingTypes: PropTypes.arrayOf(PropTypes.string),
};
