import { PAGES } from "../../../../internal/util/constants/pages";
import { COMPONENT_TYPE } from "../../constants/componentType";
import { SECTION, SECTION_NAME } from "../../constants/section";
import { extractCashClaimIndex } from "../../../../internal/components/Edit/Sections/util";

const defaultFieldAdapter = ({ value }) => value || undefined;

const selectAdapter = ({ value }) => value || {}.value;
export const datePickerAdapter = ({ value }) => {
  let date = value;
  if (value && typeof value === "object") {
    date = value.timestamp;
  }

  if (!date) {
    return undefined;
  }

  return {
    timestamp: new Date(date).getTime() || {}.value,
  };
};
const multiSelectAdapter = ({ value }) =>
  (value || []).map((option) => option.value);

export const checkboxAdapter = ({ value }) => {
  if (typeof value === "string") {
    value = JSON.parse(value);
  }
  return value || false;
};

const moneyAdapter = ({ value, currency }) => {
  value = value && value.replace(/,/g, "");

  let amount = Number(0);

  if (!isNaN(value) || value) {
    amount = Number(value);
  }

  return {
    units: Math.floor(amount),
    nanos: parseInt((amount - Math.floor(amount)) * 1e9),
    currency: currency ? currency : "USD",
  };
};

const fieldAdapter = (fieldType, type) => {
  switch (fieldType) {
    case COMPONENT_TYPE.SELECT:
      return selectAdapter;
    case COMPONENT_TYPE.MULTI_SELECT:
      return multiSelectAdapter;
    case COMPONENT_TYPE.CHECKBOX:
      return checkboxAdapter;
    case COMPONENT_TYPE.DATEPICKER:
      return datePickerAdapter;
    case COMPONENT_TYPE.INPUT:
      switch (type) {
        case "money":
          return moneyAdapter;
        default:
          return defaultFieldAdapter;
      }
    default:
      return defaultFieldAdapter;
  }
};

const opportunityInformationAdapter = ({ data }) => {
  return {
    aceOpportunityId: data["aceOpportunityId"],
  };
};

const migrationWorkloadsAdapter = ({
  schema,
  filterFields,
  data,
  fieldMap,
}) => {
  const migrationSections = Object.keys(fieldMap).filter((sectionFieldKey) =>
    sectionFieldKey.includes(SECTION_NAME.MIGRATION_WORKLOADS)
  );
  const stepSchema = schema[SECTION.MIGRATION_WORKLOADS];
  const fields = filterFields({
    section: stepSchema,
    program: data["program"],
  });

  const userInputtedFields = fields.filter((field) => !field.auto);
  const workloads = [];
  for (const section of migrationSections) {
    if (!data[fieldMap[section]["specializedWorkload"]]) continue;
    const workload = {};
    for (const field of userInputtedFields) {
      const { name, component, type } = field;
      workload[name] = fieldAdapter(
        component,
        type
      )({ value: data[fieldMap[section][name]], currency: "USD" });
    }

    workloads.push(workload);
  }

  return { workloads };
};

const migrationDetailsAdapter = ({ schema, filterFields, data, fieldMap }) => {
  const payload = defaultAdapter({
    stepTitle: SECTION.MIGRATION_DETAILS,
    stepName: SECTION_NAME.MIGRATION_DETAILS,
    data,
    fieldMap,
    filterFields,
    schema,
  });

  const { workloads } = migrationWorkloadsAdapter({
    schema,
    filterFields,
    data,
    fieldMap,
  });

  return {
    ...payload,
    workloads,
  };
};

const cashRequestInformationAdapter = ({
  schema,
  filterFields,
  data,
  fieldMap,
}) => {
  let payload = { cashClaims: {} };

  // add cash request information
  let stepSchema = schema[SECTION.CASH_REQUEST_INFORMATION];
  let fields = filterFields({
    section: stepSchema,
    program: data["program"],
  });

  let userInputtedFields = fields.filter(
    (field) => !field.auto || field.label === "PO Currency"
  );

  const localCurrency =
    data[fieldMap[SECTION_NAME.CASH_REQUEST_INFORMATION]["currency"]];

  for (const field of userInputtedFields) {
    const { name, label, component, type } = field;
    let currentCurrency = localCurrency;

    if (name === "amountUsd") {
      currentCurrency = "USD";
    }

    let value = data[fieldMap[stepSchema["name"]][name]];

    if (label === "PO Currency") {
      value = localCurrency;
    }

    payload[name] = fieldAdapter(
      component,
      type
    )({
      value,
      currency: currentCurrency,
    });
  }

  // add cash claim
  const cashClaimSections = Object.keys(fieldMap).filter((sectionFieldsKey) =>
    sectionFieldsKey.includes(SECTION_NAME.CASH_CLAIM_PLANS)
  );

  stepSchema = schema[SECTION.CASH_CLAIM_PLANS];

  fields = filterFields({
    section: stepSchema,
    program: data["program"],
  });

  userInputtedFields = fields.filter((field) => !field.auto);
  payload["cashClaims"] = {};

  for (const section of cashClaimSections) {
    let cashClaimId = data[fieldMap[section]["fundClaimId"]];
    if (!cashClaimId) {
      const cashClaimIndex = section.includes("-") ? section.split("-")[1] : 1;
      cashClaimId = `${data["fundRequestId"]}-claim-${cashClaimIndex}`;
    }

    payload["cashClaims"][cashClaimId] = { fundClaimId: cashClaimId, plan: {} };
    for (const field of userInputtedFields) {
      const { name, component, type } = field;
      let currentCurrency = localCurrency;
      if (name === "expectedRevenueRamp") {
        currentCurrency = "USD";
      }
      payload["cashClaims"][cashClaimId]["plan"][name] = fieldAdapter(
        component,
        type
      )({ value: data[fieldMap[section][name]], currency: currentCurrency });
    }
  }

  // add budget id
  if (data["budgetIdCash"]) {
    payload["budgetId"] = data["budgetIdCash"];
  }

  return payload;
};

const creditRequestDetailsAdapter = ({
  stepTitle,
  stepName,
  data,
  fieldMap,
  filterFields,
  schema,
  currency = null,
}) => {
  const fields = filterFields({
    section: schema[stepTitle],
    program: data["program"],
  });
  const userInputtedFields = fields.filter((field) => !field.auto);
  let payload = {};

  for (const field of userInputtedFields) {
    const { name, component, type } = field;
    payload[name] = fieldAdapter(
      component,
      type
    )({ value: data[fieldMap[stepName][name]], currency });
  }
  if (data["budgetIdCredit"]) {
    payload["budgetId"] = data["budgetIdCredit"];
  }
  return payload;
};

const cashClaimInvoicePaymentAdapter = ({
  stepTitle,
  data,
  fieldMap,
  filterFields,
  schema,
  cashClaimId,
}) => {
  let fieldMapKey = SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT;
  const index = extractCashClaimIndex(cashClaimId);
  if (!isNaN(index) && index > 1) {
    fieldMapKey = `${fieldMapKey}-${index}`;
  }
  const currency =
    data[fieldMap[SECTION_NAME.CASH_REQUEST_INFORMATION]["currency"]];
  return defaultAdapter({
    stepTitle,
    stepName: fieldMapKey,
    data,
    fieldMap,
    filterFields,
    schema,
    currency,
  });
};

const cashClaimActualsAdapter = ({
  stepTitle,
  data,
  fieldMap,
  filterFields,
  schema,
  cashClaimId,
}) => {
  let fieldMapKey = SECTION_NAME.CASH_CLAIM_ACTUALS;
  const index = extractCashClaimIndex(cashClaimId);
  if (!isNaN(index) && index > 1) {
    fieldMapKey = `${fieldMapKey}-${index}`;
  }
  const currency =
    data[fieldMap[SECTION_NAME.CASH_REQUEST_INFORMATION]["currency"]];
  return defaultAdapter({
    stepTitle,
    stepName: fieldMapKey,
    data,
    fieldMap,
    filterFields,
    schema,
    currency,
  });
};

/**
 * Default API adapter.
 *
 * This adapter, by default, handles steps with single sections.
 *
 * @param step metadata of a user's current step.
 * @param data user's input data.
 * @param fieldMap mapping of user's data to fields
 * @returns object ready for consumption by API
 */
const defaultAdapter = ({
  stepTitle,
  stepName,
  data,
  fieldMap,
  filterFields,
  schema,
  currency = null,
}) => {
  const fields = filterFields({
    section: schema[stepTitle],
    program: data["program"],
  });
  const userInputtedFields = fields.filter((field) => !field.auto);
  let payload = {};

  for (const field of userInputtedFields) {
    const { name, component, type } = field;
    payload[name] = fieldAdapter(
      component,
      type
    )({ value: data[fieldMap[stepName][name]], currency });
  }

  return payload;
};

const approvalDetailsAdapter = ({
  stepTitle,
  stepName,
  data,
  fieldMap,
  filterFields,
  schema,
  currency = null,
  page,
}) => {
  const payload = defaultAdapter({
    stepTitle,
    stepName,
    data,
    fieldMap,
    filterFields,
    schema,
    currency,
  });
  if (page === PAGES.AWS_APPROVAL) {
    return {
      partnerSolutionArchitect: payload["partnerSolutionArchitect"],
    };
  }

  if (page === PAGES.BUSINESS_APPROVAL) {
    return {
      exceptionApproval: payload["exceptionApproval"],
      exceptionComment: payload["exceptionComment"],
      externalApprovalSystemId: payload["externalApprovalSystemId"],
    };
  }

  return payload;
};

const adapterFactory = (stepName) => {
  switch (stepName) {
    case SECTION_NAME.OPPORTUNITY_INFORMATION:
      return opportunityInformationAdapter;
    case SECTION_NAME.CASH_REQUEST_INFORMATION:
      return cashRequestInformationAdapter;
    case SECTION_NAME.CREDIT_REQUEST_DETAILS:
      return creditRequestDetailsAdapter;
    case SECTION_NAME.MIGRATION_WORKLOADS:
      return migrationWorkloadsAdapter;
    case SECTION_NAME.MIGRATION_DETAILS:
      return migrationDetailsAdapter;
    case SECTION_NAME.CASH_CLAIM_ACTUALS:
      return cashClaimActualsAdapter;
    case SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT:
      return cashClaimInvoicePaymentAdapter;
    case SECTION_NAME.APPROVAL_DETAILS:
      return approvalDetailsAdapter;
    default:
      return defaultAdapter;
  }
};

export const formatData = (stepName) => {
  return adapterFactory(stepName);
};
