import React from "react";
import { COMPONENT_TYPE } from "../../shared/util/constants/componentType";
import {
  FUNDING_TYPE,
  PROGRAM_TYPE,
} from "../../shared/util/constants/programType";
import { SECTION, SECTION_NAME } from "../../shared/util/constants/section";
import { PAGES } from "../util/constants/pages";
import { PROGRAM_ENGAGEMENT_NEW_ID_GUIDE_LINK } from "../../shared/util/constants/constantValues";
import { grantSectionEditingToPage } from "./util.js";

export const fundRequestInformationSection = {
  title: SECTION.FUND_REQUEST_INFORMATION,
  name: SECTION_NAME.FUND_REQUEST_INFORMATION,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "activityName",
      label: "Activity/Project Name",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "pifProgramName",
      label: "Program Name",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.PIF],
    },
    {
      name: "pifFundingMotion",
      label: "Funding Motion",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      Required: [PROGRAM_TYPE.PIF],
    },
    {
      name: "businessDescription",
      label: "Business Description",
      placeholder:
        "Include the reason for requesting funding, including business justification, project summary, objectives, success criteria, MDF usage reason for cash and credit, etc.",
      auto: false,
      component: COMPONENT_TYPE.TEXTAREA,
      type: "string",
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "awsCalculatorUrl",
      label: "AWS Pricing Calculator",
      placeholder:
        "Enter the URL for AWS Pricing Calculator." +
        "For fund requests that require multiple AWS Pricing Calculators, " +
        "enter each hyperlink/URL string separated by a comma (,) and ensure there are no " +
        "empty spaces in between each entry",
      auto: false,
      component: COMPONENT_TYPE.TEXTAREA,
      type: "string",
      edit: [PAGES.AWS_APPROVAL],
      Optional: [
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SANDBOX,
      ],
    },
    {
      name: "sandboxDesignWinActivity",
      label: "Sandbox Design Win Activity",
      auto: false,
      key: "sandboxDesignWinActivity",
      component: COMPONENT_TYPE.SELECT,
      type: "string",
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.SANDBOX],
    },
    {
      name: "fundRequestOwner",
      label: "Fund Request Owner",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "assignee",
      label: "Assigned To",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "status",
      label: "Active Status",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "fundRequestId",
      label: "Fund Request Id",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "budgetYear",
      label: "Budget Year",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "thirdPartyFunding",
      label: "3rd Party Funding",
      component: COMPONENT_TYPE.SELECT,
      key: "thirdPartyFunding",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Optional: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP],
    },
    {
      name: "thirdPartyFundingId",
      label: "3rd Party Funding ID",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      edit: [PAGES.BUSINESS_APPROVAL],
      Optional: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP],
    },
    {
      name: "majorityPublicSector",
      label: "Will this activity be targeting 50%+ public sector customers?",
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      type: "boolean",
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "program",
      label: "Program",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "subProgram",
      label: "Sub Program",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "fundingType",
      label: "Funding Type",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "arn",
      label: "Amazon Resource Name (ARN)",
      description:
        "Please provide the character alphanumeric ARN string for Well-Architected Partner Program request.",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      hidden: false,
      type: "string",
      edit: [],
      Required: [PROGRAM_TYPE.PIF],
    },
  ],
};

export const opportunityInformationSection = {
  title: SECTION.OPPORTUNITY_INFORMATION,
  name: SECTION_NAME.OPPORTUNITY_INFORMATION,
  supportedProgram: [PROGRAM_TYPE.MISC],
  fields: [
    {
      name: "opportunityId",
      label: "Opportunity Id",
      auto: false,
      component: COMPONENT_TYPE.OPPORTUNITY_SELECT,
      errorMessage: "Please select a valid opportunity.",
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "awsOpportunityId",
      label: "AWS Opportunity ID",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "awsPartnerManager",
      label: "AWS Partner Manager",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "textstring",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "customerCompanyName",
      label: "Customer Company Name",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "opportunityOwner",
      label: "Opportunity Owner",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "opportunityOwnership",
      label: "Opportunity Ownership",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "targetCloseDate",
      label: "Target Close Date",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "projectDescription",
      label: "Project Description",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "status",
      label: "Status",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "aceValidationStage",
      label: "Ace Validation Stage",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "stage",
      label: "AWS Opportunity Stage",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "customerBizUnit",
      label: "Customer Biz Unit",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "industryVertical",
      label: "Industry Vertical",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "territory",
      label: "Territory",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "wwps",
      label: "WWPS",
      auto: true,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const projectInformationSection = {
  title: SECTION.PROJECT_INFORMATION,
  name: SECTION_NAME.PROJECT_INFORMATION,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "awsAccountId",
      label: "AWS Account Id for this Project",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[0-9]{12}$",
      errorMessage: "must be a 12 digit number.",
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "annualRunRateForMisc",
      label: "Annual Recurring Revenue (ARR) Once In Production (USD)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL, PAGES.BUSINESS_APPROVAL],
      Required: [PROGRAM_TYPE.MISC],
    },
    {
      name: "plannedStartDate",
      label: "Planned Delivery Start Date",
      description:
        "This is the earliest start date of your activity. Work may begin 14 business days after the fund request submission date.",
      auto: false,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "plannedEndDate",
      label: "Planned Delivery End Date",
      description:
        "This is the date when your activity ends. Cash claims are typically due on 30 days after the activity end date.",
      auto: false,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "expirationDate",
      label: "Funding Expiration Date (MM/DD/YYYY)",
      auto: true,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "extendedStartDate",
      label: "Extended Start Date",
      auto: true,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [],
      Optional: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "extendedEndDate",
      label: "Extended End Date",
      auto: true,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [],
      Optional: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "extendedReason",
      label: "Reason for Extension",
      auto: true,
      component: COMPONENT_TYPE.TEXTAREA,
      edit: [],
      Optional: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "executedCountry",
      label: "Country Activity is Executed",
      auto: false,
      key: "countries",
      component: COMPONENT_TYPE.SELECT,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "executedState",
      label: "Location/State Activity is Executed (Select All That Apply)",
      auto: false,
      key: "locationSlashStateActivityExecuted",
      component: COMPONENT_TYPE.MULTI_SELECT,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "locationGeo",
      label: "Project Location GEO",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "locationSubRegion",
      label: "Project Location Sub-Region",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "projectType",
      label: "Project Type",
      auto: false,
      key: "projectType",
      component: COMPONENT_TYPE.SELECT,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "customerConsidering",
      label: "Customer Also Considering",
      auto: false,
      component: COMPONENT_TYPE.SELECT,
      key: "customerAlsoConsidering",
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
      Optional: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.PIF],
    },
    {
      name: "programEngagementId",
      label: "Program Engagement Id",
      description: (
        <React.Fragment>
          Please input the last 10 alphanumeric characters of the Migration
          Program Engagement ID.{" "}
          <a
            href={PROGRAM_ENGAGEMENT_NEW_ID_GUIDE_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn
          </a>{" "}
          how to retrieve the ID using the five digit legacy ID.
        </React.Fragment>
      ),
      placeholder: 'Program Engagement-12345 would be input as "12345"',
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[A-Za-z0-9]*$",
      errorMessage: "must be alpha numeric.",
      edit: [PAGES.AWS_APPROVAL, PAGES.BUSINESS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "migrationBdApproved",
      label: "Migration BD Approved",
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [PAGES.AWS_APPROVAL, PAGES.BUSINESS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "workloadName",
      label: "Workload Name",
      description: "Please select the workload relating to this project",
      component: COMPONENT_TYPE.SELECT,
      auto: false,
      key: "pifWorkloads",
      edit: [PAGES.BUSINESS_APPROVAL],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "newServiceDeployed",
      label: "New Service Deployed",
      description:
        "Please check the box if new service deployed with this project.",
      component: COMPONENT_TYPE.CHECKBOX,
      auto: true,
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "projectRoi",
      label: "Project ROI",
      description: (
        <React.Fragment>
          <div>
            Revenue/Investment Ratio (1 year) = (Projected Monthly Run Rate Once
            In Production X 12 months) / (Total Credit Request Value + Requested
            Cash Amount)
          </div>
          <div>
            Revenue/Investment Ratio (3 years) = (Projected Monthly Run Rate
            Once In Production X 36 months) / (Total Credit Request Value +
            Requested Cash Amount)
          </div>
        </React.Fragment>
      ),
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      type: "string",
      pattern: "^-?(?:[0-9]{1,2})(?:\\.[0-9]{1,2})?$",
      errorMessage:
        "Must be a value between -100.00 and 100.00 (to the nearest 2 decimal places)",
      edit: [PAGES.BUSINESS_APPROVAL],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "otherNotes",
      label: "Other Notes",
      placeholder: "Additional information relating to this project.",
      component: COMPONENT_TYPE.TEXTAREA,
      auto: false,
      edit: [PAGES.BUSINESS_APPROVAL],
      Optional: [PROGRAM_TYPE.PIF],
    },
  ],
};

export const migrationDetails = {
  title: SECTION.MIGRATION_DETAILS,
  name: SECTION_NAME.MIGRATION_DETAILS,
  supportedProgram: [PROGRAM_TYPE.SMP],
  fields: [
    {
      name: "peCustomer",
      label: "Eligible for PE Customer Incentive",
      component: COMPONENT_TYPE.CHECKBOX,
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "specialCustomerIncentive",
      label: "Additional Incentive for Special Customer",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      edit: [],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "informationalLetterId",
      label: "Informational Letter ID",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      edit: [PAGES.PRE_APPROVAL],
      Optional: [PROGRAM_TYPE.SMP],
    },
    {
      name: "mraCompleted",
      label: "MRA Completed",
      component: COMPONENT_TYPE.CHECKBOX,
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.SMP],
    },
  ],
};

export const migrationWorkloads = {
  title: SECTION.MIGRATION_WORKLOADS,
  name: SECTION_NAME.MIGRATION_WORKLOADS,
  supportedProgram: [PROGRAM_TYPE.SMP],
  fields: [
    {
      name: "specializedWorkload",
      label: "Specialized Workloads",
      component: COMPONENT_TYPE.SELECT,
      auto: false,
      key: "specializedWorkloads",
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Optional: [PROGRAM_TYPE.SMP],
    },
    {
      name: "postMigrationAnnualRunRate",
      label: "Post-Migration ARR of this Workload (USD)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Optional: [PROGRAM_TYPE.SMP],
    },
    {
      name: "additionalIncentive",
      label: "Additional Incentives for Specialized Workloads",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      edit: [],
      Optional: [PROGRAM_TYPE.SMP],
    },
  ],
};

export const publicSectorDetailsSection = {
  title: SECTION.PUBLIC_SECTOR_DETAILS,
  name: SECTION_NAME.PUBLIC_SECTOR_DETAILS,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "customerRfx",
      label: "Responding to Customer RFX",
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "wwpsPdm",
      label: "WWPS PDM",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "wwpsConsent",
      label: "WWPS Consent",
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "wwpsConsentOwner",
      label: "WWPS Consent Owner",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "wwpsConsentDate",
      label: "WWPS Consent Date",
      auto: true,
      hidden: true,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const cashRequestInformationSection = {
  title: SECTION.CASH_REQUEST_INFORMATION,
  name: SECTION_NAME.CASH_REQUEST_INFORMATION,
  supportedProgram: [
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "currency",
      label: "Currency",
      description:
        "The currency you select must match the preferred currency selected in your company's Amazon Payee Central.",
      component: COMPONENT_TYPE.SELECT,
      key: "currencies",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "totalMdfCashAmount",
      label: "Total Requested MDF Cash Amount (Local Currency)",
      description:
        "Sum of Requested MDF Cash Funding from all activities below.",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      edit: [],
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "totalCostOfActivity",
      label: "Total Cost of Activity (Local Currency)",
      description: "Total cost on SOW or Project Plan.",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "partnerContribution",
      label: "Partner Contribution (Local Currency)",
      description: "Project cost shared by AWS Partner.",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "otherPartyContribution",
      label: "Customer Contribution (Local Currency)",
      description: "Project cost shared by customer or other parties.",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "cashFundingAmount",
      label: "Requested Cash Funding Amount (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.MISC, PROGRAM_TYPE.POC, PROGRAM_TYPE.PIF],
    },
    {
      name: "invoiceEntityName",
      label: "Invoice Entity Name",
      placeholder:
        "This name must match your company’s official tax documents and your Amazon Payee Central Account.",
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "invoiceRemitAddress",
      label: "Invoice Remit Address",
      placeholder:
        "This address must match your company’s official tax documents, invoices and your Amazon Payee Central Account",
      component: COMPONENT_TYPE.TEXTAREA,
      type: "string",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "invoiceRemitCountry",
      label: "Invoice Remit Address Country",
      component: COMPONENT_TYPE.SELECT,
      key: "countries",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },

    {
      name: "numberOfClaims",
      label: "Number of Claims",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.PIF,
      ],
    },
    //////
    {
      name: "requestNumber",
      label: "PO Req #",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      edit: [
        PAGES.FINANCE_APPROVAL,
        PAGES.PRE_APPROVAL,
        PAGES.CASH_CLAIM_APPROVAL,
      ],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "poCurrency",
      label: "PO Currency",
      component: COMPONENT_TYPE.SELECT,
      auto: true,
      key: "currencies",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "amount",
      label: "PO Amount (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "amountUsd",
      label: "PO Amount (USD)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "number",
      label: "PO Number",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      edit: [
        PAGES.FINANCE_APPROVAL,
        PAGES.PRE_APPROVAL,
        PAGES.CASH_CLAIM_APPROVAL,
      ],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "issuranceDate",
      label: "PO Issuance Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: false,
      edit: [
        PAGES.FINANCE_APPROVAL,
        PAGES.PRE_APPROVAL,
        PAGES.CASH_CLAIM_APPROVAL,
      ],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "issured",
      label: "PO Issued",
      component: COMPONENT_TYPE.CHECKBOX,
      auto: false,
      edit: [
        PAGES.FINANCE_APPROVAL,
        PAGES.PRE_APPROVAL,
        PAGES.CASH_CLAIM_APPROVAL,
      ],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "approvedCashAmount",
      label: "Approved Cash Amount (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "unclaimedAmount",
      label: "Unclaimed Amount (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: true,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "currencyConversionRateToUsd",
      label: "USD Conversion Rate",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "strategicCollaborationAgreementCash",
      label: "Strategic Collaboration Agreement",
      auto: false,
      component: COMPONENT_TYPE.SCA,
      errorMessage: "No SCA selected.",
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.SANDBOX,
      ],
    },
  ],
};

export const cashClaimPlansSection = {
  title: SECTION.CASH_CLAIM_PLANS,
  name: SECTION_NAME.CASH_CLAIM_PLANS,
  supportedProgram: [
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "fundClaimId",
      label: "Fund Claim Id",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "mdfActivityType",
      label: "MDF Cash Activity Type",
      key: "mdfActivityType",
      component: COMPONENT_TYPE.SELECT,
      auto: false,
      edit: [],
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "startDate",
      label: "Planned Milestone/Activity Start Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: false,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "endDate",
      label: "Planned Milestone/Activity End Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: false,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "dueDate",
      label: "Claim Due Date",
      description:
        "All claims must be submitted before this date with the required Proof of Performance attached. The claim due date is calculated as 30 days after the activity planned end date and prior to December 15 of each year.",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: true,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "percentageCompletion",
      label: "% of Project Complete at this Milestone",
      description:
        "Input the percentage of work completed at time of milestone claim.",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      type: "string",
      pattern: "^[1-9][0-9]?$|^100$",
      errorMessage: "must be a whole number less than 100.",
      edit: [],
      Required: [PROGRAM_TYPE.SMP],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "expectedRevenueRamp",
      label: "Expected Revenue Ramp for the Milestone",
      placeholder: "Quarterly revenue ramp should be shown in USD",
      description:
        "Please input the expected quarterly revenue ramp, which will be validated at time of claim for payment.",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      type: "money",
      pattern: "^[0-9]*$",
      errorMessage: "must be a number.",
      edit: [],
      Required: [PROGRAM_TYPE.SMP],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "totalCostOfMdfActivity",
      label: "Total Cost of MDF Activity (Local Currency)",
      placeholder:
        "This is the total cash expense you expect to pay at the end of the activity",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "requestedMdfCashFunding",
      label: "Requested MDF Cash Funding (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "mdfFundingPercentage",
      label: "MDF Funding %",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [],
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "mdfNumberOfProjectedLeads",
      label: "MDF Campaign # of projected leads",
      component: COMPONENT_TYPE.INPUT,
      auto: false,
      type: "string",
      pattern: "^[0-9]*$",
      errorMessage: "must be a number.",
      edit: [],
      Required: [PROGRAM_TYPE.MDF],
    },
  ],
};

export const cashClaimActualsSection = {
  title: SECTION.CASH_CLAIM_ACTUALS,
  name: SECTION_NAME.CASH_CLAIM_ACTUALS,
  supportedProgram: [
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
  ],
  fields: [
    {
      name: "claimAmount",
      label: "Claim Amount (Local Currency)",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "awsAccountId",
      label: "AWS Account ID for Actual Delivery",
      description:
        "Please provide the AWS Account ID associated with this activity or project, and if it's different from the AWS Account ID provided earlier. ",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[0-9]{12}$",
      errorMessage: "must be a 12 digit number.",
      edit: [],
      Optional: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "startDate",
      label: "Actual Milestone/Activity Start Date",
      auto: false,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "endDate",
      label: "Actual Milestone/Activity End Date",
      auto: false,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "productionDate",
      label: "Actual Production Date",
      auto: false,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [],
      Optional: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "customerSignOffAttached",
      label: "Proof-Of-Performance Attached",
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
      Optional: [PROGRAM_TYPE.PIF],
    },
    {
      name: "completed",
      label: "Claim Completed",
      auto: true,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const cashClaimInvoicePaymentSection = {
  title: SECTION.CASH_CLAIM_INVOICE_PAYMENT,
  name: SECTION_NAME.CASH_CLAIM_INVOICE_PAYMENT,
  supportedProgram: [
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "currency",
      label: "Invoice Currency",
      component: COMPONENT_TYPE.SELECT,
      key: "currencies",
      auto: true,
      edit: [PAGES.CASH_CLAIM_APPROVAL],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "amount",
      label: "Invoice Amount (Local Currency)",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [PAGES.CASH_CLAIM_APPROVAL],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "scheduledPaidDate",
      label: "Scheduled Paid Date",
      component: COMPONENT_TYPE.DATEPICKER,
      auto: false,
      edit: [PAGES.CASH_CLAIM_APPROVAL],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "description",
      label: "Description",
      component: COMPONENT_TYPE.TEXTAREA,
      auto: false,
      edit: [PAGES.CASH_CLAIM_APPROVAL],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const migrationEngagementOverviewSection = {
  title: SECTION.MIGRATION_ENGAGEMENT_OVERVIEW,
  name: SECTION_NAME.MIGRATION_ENGAGEMENT_OVERVIEW,
  supportedProgram: [PROGRAM_TYPE.SMP],
  fields: [
    {
      name: "mpeId",
      label: "Program Engagement Id",
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      description: (
        <React.Fragment>
          Please input the last 10 alphanumeric characters of the Migration
          Program Engagement ID.{" "}
          <a
            href={PROGRAM_ENGAGEMENT_NEW_ID_GUIDE_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn
          </a>{" "}
          how to retrieve the ID using the five digit legacy ID.
        </React.Fragment>
      ),
      placeholder: "Enter Migration Program Engagement Id",
      auto: false,
      pattern: "^[A-Za-z0-9]*$",
      errorMessage:
        "Invalid Migration Program Engagement Id, Must be alpha numeric.",
      edit: [PAGES.AWS_APPROVAL, PAGES.BUSINESS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "recordStatus",
      label: "Record Status",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "engagementStatus",
      label: "Engagement Status",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "migrationArr",
      label: "Estimated Migration ARR (USD)",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "consultingPartnerEngagement",
      label: "Consulting Partner Engagement",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "agreementCategory",
      label: "Agreement Category",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "privateEquityBacked",
      label: "Private Equity Backed",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
  ],
};

export const migrationEngagementRASection = {
  title: SECTION.MIGRATION_ENGAGEMENT_RA,
  name: SECTION_NAME.MIGRATION_ENGAGEMENT_RA,
  supportedProgram: [PROGRAM_TYPE.SMP],
  fields: [
    {
      name: "workshopCompletionDate",
      label: "Workshop Completion Date",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "readinessAssessmentStatus",
      label: "Readiness assessment status",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "deliveredBy",
      label: "Delivered By",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "readoutPresentationDate",
      label: "Readout Presentation Date",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "mraMethdology",
      label: "MRA Methodology",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
  ],
};

export const migrationEngagementOLASection = {
  title: SECTION.MIGRATION_ENGAGEMENT_OLA,
  name: SECTION_NAME.MIGRATION_ENGAGEMENT_OLA,
  supportedProgram: [PROGRAM_TYPE.SMP],
  fields: [
    {
      name: "olaCompletionDate",
      label: "OLA Completion Date",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "confirmed",
      label: "OLA Confirmed",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
    {
      name: "olaStatus",
      label: "OLA Status",
      component: COMPONENT_TYPE.INPUT,
      auto: true,
      edit: [PROGRAM_TYPE.SMP],
      Required: [PROGRAM_TYPE.SMP],
    },
  ],
};

export const creditRequestDetailsSection = {
  title: SECTION.CREDIT_REQUEST_DETAILS,
  name: SECTION_NAME.CREDIT_REQUEST_DETAILS,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "valuePerCode",
      label: "Value per Credit Code (USD)",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "numberOfCodes",
      label: "Number of Codes",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      type: "number",
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "currency",
      label: "Currency",
      description:
        "The currency you select must match the preferred currency selected in your company's Amazon Payee Central.",
      component: COMPONENT_TYPE.SELECT,
      key: "currencies",
      auto: false,
      Required: [PROGRAM_TYPE.POC, PROGRAM_TYPE.PIF],
    },
    {
      name: "totalCostOfActivity",
      label: "Total Cost of Activity (Local Currency)",
      description: "Total cost on SOW or Project Plan.",
      placeholder:
        "This is the total cash expense you expect to pay at the end of the activity",
      component: COMPONENT_TYPE.INPUT,
      type: "money",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.POC],
    },
    {
      name: "requestedCreditAmount",
      label: "Requested Credit Amount (USD)",
      auto: true,
      type: "money",
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "requestedCreditAmountEditable",
      label: "Requested Credit Amount (USD)",
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      type: "money",
      component: COMPONENT_TYPE.INPUT,
      Optional: [],
    },
    {
      name: "awsAccountId",
      label: "AWS Account ID",
      description:
        'Credits will be auto-redeemed to the AWS Account ID used for this project. If you need to update the ID, please go back to the "Project Information" step.',
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      type: "string",
      pattern: "^[0-9]{12}$",
      errorMessage: "must be a 12 digit number.",
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "mdfCreditActivityType",
      label: "MDF Credit Activity Type",
      key: "mdfCreditActivityType",
      component: COMPONENT_TYPE.SELECT,
      auto: false,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.MDF],
    },
    ///
    {
      name: "accountManagerApproved",
      label: "Account Manager Approved",
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [PAGES.FINANCE_APPROVAL],
      Optional: [PROGRAM_TYPE.SANDBOX, PROGRAM_TYPE.MISC, PROGRAM_TYPE.SMP],
    },
    {
      name: "approvedCreditAmount",
      label: "Approved Credit Amount (USD)",
      auto: true,
      hidden: true,
      type: "money",
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "issuedCreditAmount",
      label: "Issued Credit Amount (USD)",
      auto: true,
      hidden: true,
      type: "money",
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "secondTrancheClaimConsent",
      label: "Second Tranche Claim Consent",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      Required: [PROGRAM_TYPE.MISC],
    },
    {
      name: "strategicCollaborationAgreementCredit",
      label: "Strategic Collaboration Agreement",
      auto: false,
      component: COMPONENT_TYPE.SCA,
      errorMessage: "No SCA selected.",
      type: "string",
      edit: [],
      Required: [
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.SANDBOX,
      ],
    },
  ],
};

export const attachmentsSection = {
  title: SECTION.ATTACHMENTS,
  name: SECTION_NAME.ATTACHMENTS,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
    PROGRAM_TYPE.MAP2024,
  ],
  fields: [
    {
      name: "attachment",
      label:
        "Please attach all supporting documents as required by each funding type, e.g., Project Plan/Checklist, AWS Pricing Calculator, Migration Capability Review, etc.",
      Required: [
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
        PROGRAM_TYPE.MAP2024,
      ],
      component: COMPONENT_TYPE.FILE_UPLOAD,
    },
  ],
};

export const apnPartnerDetailsSection = {
  title: SECTION.APN_PARTNER_DETAILS,
  name: SECTION_NAME.APN_PARTNER_DETAILS,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "partnerName",
      label: "APN Partner",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "accountGeo",
      label: "APN Account GEO",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "accountSubRegion",
      label: "APN Account SubRegion",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "partnerVendorId",
      label: "Partner Vendor ID",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "partnerSpmsId",
      label: "SPMS Id",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "partnerGsiAccount",
      label: "GSI Account",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "strategicCollaborationAgreement",
      label: "Strategic Collaboration Agreement",
      auto: true,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "fundingAgreementType",
      label: "Funding Agreement Type",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "partnerContact",
      label: "APN Partner Contact",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "partnerEmail",
      label: "Partner Contact Email",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "partnerTitle",
      label: "Partner Contact Title",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "partnerPhone",
      label: "Partner Contact Phone",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
  ],
};

export const approvalDetailsSection = {
  title: SECTION.APPROVAL_DETAILS,
  name: SECTION_NAME.APPROVAL_DETAILS,
  supportedProgram: [
    PROGRAM_TYPE.SANDBOX,
    PROGRAM_TYPE.MISC,
    PROGRAM_TYPE.MDF,
    PROGRAM_TYPE.SMP,
    PROGRAM_TYPE.POC,
    PROGRAM_TYPE.PIF,
  ],
  fields: [
    {
      name: "partnerSolutionArchitect",
      label: "Partner Solutions Architect",
      description:
        "Please identify a Partner Solution Architect who should review and approve this" +
        ' funding. Please input the correct PSA\'s alias. - do not include "@amazon"',
      auto: false,
      component: COMPONENT_TYPE.PSA_SELECT,
      edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL],
      Required: [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
    },
    {
      name: "eligibleForPsaAutoApproval",
      label: "Is Eligible for SOW Auto Approval?",
      description:
        "Please identify a Partner Solution Architect who should review and approve this funding.",
      auto: true,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [],
      Required: [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
    },
    {
      name: "globalFundingOps",
      label: "Global Funding Ops",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "sandboxGeoLeads",
      label: "Sandbox GEO Leads",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [PROGRAM_TYPE.SANDBOX],
    },
    {
      name: "trainingMdfGeoLeads",
      label: "Training MDF GEO Leads",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [PROGRAM_TYPE.MDF],
    },
    {
      name: "awsSalesRep",
      label: "AWS Sales Rep",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "partnerSalesRep",
      label: "Partner Sales Rep",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "awsAccountManager",
      label: "AWS Account Manager",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "partnerSuccessManager",
      label: "Partner Success Manager",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "partnerDevelopmentManager",
      label: "Partner Development Manager",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "awsIsvSuccessManager",
      label: "AWS ISV Success Manager",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [PROGRAM_TYPE.SMP, PROGRAM_TYPE.POC],
    },
    {
      name: "initiativeOwnerApproval",
      label: "Initiative Owner Approval",
      auto: true,
      component: COMPONENT_TYPE.INPUT,
      edit: [],
      Required: [PROGRAM_TYPE.PIF],
    },
    {
      name: "dateSubmittedForApproval",
      label: "Date Submitted for Approval",
      auto: true,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "dateApproved",
      label: "Date Approved",
      auto: true,
      component: COMPONENT_TYPE.DATEPICKER,
      edit: [],
      Required: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
        PROGRAM_TYPE.PIF,
      ],
    },
    {
      name: "exceptionApproval",
      label: "Exception Approval",
      auto: false,
      component: COMPONENT_TYPE.CHECKBOX,
      edit: [PAGES.BUSINESS_APPROVAL],
      Optional: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "exceptionComment",
      label: "Exception Comments (Internal Only)",
      auto: false,
      component: COMPONENT_TYPE.TEXTAREA,
      edit: [PAGES.BUSINESS_APPROVAL],
      Optional: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
    {
      name: "externalApprovalSystemId",
      label: "External Approval System ID",
      placeholder:
        "Please provide the system and the ID for the external approval, e.g. SIM (APN-13367), Amazon Approval (3710006), etc.",
      auto: false,
      component: COMPONENT_TYPE.INPUT,
      edit: [PAGES.BUSINESS_APPROVAL],
      Optional: [
        PROGRAM_TYPE.SANDBOX,
        PROGRAM_TYPE.MISC,
        PROGRAM_TYPE.MDF,
        PROGRAM_TYPE.SMP,
        PROGRAM_TYPE.POC,
      ],
    },
  ],
};

export const migrationEngagementsSection = {
  title: SECTION.MIGRATION_PROGRAM_ENGAGEMENTS,
  name: SECTION_NAME.MIGRATION_PROGRAM_ENGAGEMENTS,
  supportedProgram: [PROGRAM_TYPE.SMP],
  fields: [
    ...migrationEngagementOverviewSection.fields,
    ...migrationEngagementRASection.fields,
    ...migrationEngagementOLASection.fields,
  ],
};

export const filterFields = ({
  section,
  program,
  hide,
  flag = false,
  fieldName = null,
}) => {
  //TODO: What can admins see?
  let fields = section.fields.filter(
    (field) =>
      (field.Required && field.Required.includes(program)) ||
      (field.Optional && field.Optional.includes(program))
  );
  if (hide) {
    fields = fields.filter((field) => !field.hidden);
  }

  if (flag) {
    fields.forEach((field) => {
      if (fieldName && fieldName === field.name) {
        field.hidden = false;
      }
    });
  } else {
    fields = fields.filter((field) => fieldName !== field.name);
  }
  return fields;
};

export const INTERNAL_LAYOUT = [
  {
    title: SECTION.FUND_REQUEST_INFORMATION,
    name: SECTION_NAME.FUND_REQUEST_INFORMATION,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL, PAGES.BUSINESS_APPROVAL],
    sections: [SECTION.FUND_REQUEST_INFORMATION],
  },
  {
    title: SECTION.OPPORTUNITY_INFORMATION,
    name: SECTION_NAME.OPPORTUNITY_INFORMATION,
    supportedProgram: [
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    edit: [],
    sections: [SECTION.OPPORTUNITY_INFORMATION],
  },
  {
    title: SECTION.PROJECT_INFORMATION,
    name: SECTION_NAME.PROJECT_INFORMATION,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    edit: [PAGES.AWS_APPROVAL, PAGES.BUSINESS_APPROVAL, PAGES.TECH_APPROVAL],
    sections: [SECTION.PROJECT_INFORMATION],
  },
  {
    title: SECTION.MIGRATION_DETAILS,
    name: SECTION_NAME.MIGRATION_DETAILS,
    supportedProgram: [PROGRAM_TYPE.SMP],
    multiSection: {
      title: SECTION.MIGRATION_WORKLOADS,
      minSections: 1,
      maxSections: 7,
      addButtonText: "Add Another Workload",
      addButtonMessage:
        'Click the "Add Another Workload" button to add more workloads.',
      addButtonDisabledMessage: `Max number of workloads is 7.`,
    },
    edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL, PAGES.PRE_APPROVAL],
    sections: [SECTION.MIGRATION_DETAILS, SECTION.MIGRATION_WORKLOADS],
  },
  {
    title: SECTION.CASH_REQUEST_INFORMATION,
    name: SECTION_NAME.CASH_REQUEST_INFORMATION,
    supportedProgram: [
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    supportedFundingType: [FUNDING_TYPE.CASH, FUNDING_TYPE.COMBO],
    multiSection: {
      title: SECTION.CASH_CLAIM_PLANS,
      minSections: 1,
      maxSections: 10,
      addButtonText: "Add Another Activity/Milestone",
      addButtonMessage:
        'Click the "Add Another Activity/Milestone" button to add more claims.',
      addButtonDisabledMessage: `Max number of cash claims is 10.`,
    },
    edit: [
      PAGES.AWS_APPROVAL,
      PAGES.TECH_APPROVAL,
      PAGES.FINANCE_APPROVAL,
      PAGES.PRE_APPROVAL,
      PAGES.CASH_CLAIM_APPROVAL,
    ],
    sections: [SECTION.CASH_REQUEST_INFORMATION, SECTION.CASH_CLAIM_PLANS],
  },
  {
    title: SECTION.CREDIT_REQUEST_DETAILS,
    name: SECTION_NAME.CREDIT_REQUEST_DETAILS,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    supportedFundingType: [FUNDING_TYPE.CREDIT, FUNDING_TYPE.COMBO],
    edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL, PAGES.FINANCE_APPROVAL],
    sections: [SECTION.CREDIT_REQUEST_DETAILS],
  },
  {
    title: SECTION.MIGRATION_PROGRAM_ENGAGEMENTS,
    name: SECTION_NAME.MIGRATION_PROGRAM_ENGAGEMENTS,
    supportedFundingType: [FUNDING_TYPE.CASH, FUNDING_TYPE.CREDIT],
    supportedProgram: [PROGRAM_TYPE.SMP],
    edit: [PAGES.AWS_APPROVAL],
    sections: [
      SECTION.MIGRATION_ENGAGEMENT_OVERVIEW,
      SECTION.MIGRATION_ENGAGEMENT_RA,
      SECTION.MIGRATION_ENGAGEMENT_OLA,
    ],
  },
  {
    title: SECTION.ATTACHMENTS,
    name: SECTION_NAME.ATTACHMENTS,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
      PROGRAM_TYPE.MAP2024,
    ],
    edit: [
      PAGES.AWS_APPROVAL,
      PAGES.BUSINESS_APPROVAL,
      PAGES.CASH_CLAIM_APPROVAL,
      PAGES.FINANCE_APPROVAL,
    ],
    sections: [SECTION.ATTACHMENTS],
  },
  {
    title: SECTION.APN_PARTNER_DETAILS,
    name: SECTION_NAME.APN_PARTNER_DETAILS,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    edit: [],
    sections: [SECTION.APN_PARTNER_DETAILS],
  },
  {
    title: SECTION.APPROVAL_DETAILS,
    name: SECTION_NAME.APPROVAL_DETAILS,
    supportedProgram: [
      PROGRAM_TYPE.SANDBOX,
      PROGRAM_TYPE.MISC,
      PROGRAM_TYPE.MDF,
      PROGRAM_TYPE.SMP,
      PROGRAM_TYPE.POC,
      PROGRAM_TYPE.PIF,
    ],
    edit: [PAGES.AWS_APPROVAL, PAGES.TECH_APPROVAL, PAGES.BUSINESS_APPROVAL],
    sections: [SECTION.APPROVAL_DETAILS],
  },
];

export const schema = {
  [SECTION.FUND_REQUEST_INFORMATION]: fundRequestInformationSection,
  [SECTION.OPPORTUNITY_INFORMATION]: opportunityInformationSection,
  [SECTION.PROJECT_INFORMATION]: projectInformationSection,
  [SECTION.PUBLIC_SECTOR_DETAILS]: publicSectorDetailsSection,
  [SECTION.MIGRATION_DETAILS]: migrationDetails,
  [SECTION.MIGRATION_WORKLOADS]: migrationWorkloads,
  [SECTION.CASH_REQUEST_INFORMATION]: cashRequestInformationSection,
  [SECTION.CASH_CLAIM_PLANS]: cashClaimPlansSection,
  [SECTION.CASH_CLAIM_ACTUALS]: cashClaimActualsSection,
  [SECTION.CASH_CLAIM_INVOICE_PAYMENT]: cashClaimInvoicePaymentSection,
  [SECTION.CREDIT_REQUEST_DETAILS]: creditRequestDetailsSection,
  [SECTION.MIGRATION_PROGRAM_ENGAGEMENTS]: migrationEngagementsSection,
  [SECTION.MIGRATION_ENGAGEMENT_OVERVIEW]: migrationEngagementOverviewSection,
  [SECTION.MIGRATION_ENGAGEMENT_RA]: migrationEngagementRASection,
  [SECTION.MIGRATION_ENGAGEMENT_OLA]: migrationEngagementOLASection,
  [SECTION.CASH_CLAIM_ACTUALS]: cashClaimActualsSection,
  [SECTION.CASH_CLAIM_INVOICE_PAYMENT]: cashClaimInvoicePaymentSection,
  [SECTION.ATTACHMENTS]: attachmentsSection,
  [SECTION.APN_PARTNER_DETAILS]: apnPartnerDetailsSection,
  [SECTION.APPROVAL_DETAILS]: approvalDetailsSection,
};

export const getInternalTemplate = ({
  fundingType,
  migrationPhase,
  program,
}) => {
  const fundRequestTemplate = INTERNAL_LAYOUT.filter((section) =>
    section.supportedFundingType
      ? section.supportedFundingType.includes(fundingType) &&
        section.supportedProgram.includes(program)
      : section.supportedProgram.includes(program)
  );
  let updatedFundRequestTemplate = fundRequestTemplate;
  if (program === PROGRAM_TYPE.SMP) {
    updatedFundRequestTemplate = grantSectionEditingToPage(
      PAGES.BUSINESS_APPROVAL,
      SECTION_NAME.MIGRATION_DETAILS,
      fundRequestTemplate
    );
    updatedFundRequestTemplate = grantSectionEditingToPage(
      PAGES.BUSINESS_APPROVAL,
      SECTION_NAME.PROJECT_INFORMATION,
      updatedFundRequestTemplate
    );
  }
  if (program === PROGRAM_TYPE.POC) {
    updatedFundRequestTemplate = grantSectionEditingToPage(
      PAGES.BUSINESS_APPROVAL,
      SECTION_NAME.PROJECT_INFORMATION,
      fundRequestTemplate
    );
  }
  if (program === PROGRAM_TYPE.MISC) {
    updatedFundRequestTemplate = grantSectionEditingToPage(
      PAGES.BUSINESS_APPROVAL,
      SECTION_NAME.PROJECT_INFORMATION,
      fundRequestTemplate
    );
  }

  const template = {};

  for (let sectionTemplate of updatedFundRequestTemplate) {
    if (
      program === PROGRAM_TYPE.SMP &&
      migrationPhase &&
      migrationPhase !== "Migrate and Modernize Phase" &&
      sectionTemplate.name === SECTION_NAME.MIGRATION_DETAILS
    ) {
      continue;
    }
    template[sectionTemplate.name] = sectionTemplate;
  }
  return template;
};
