import React, { useEffect } from "react";
import {
  NonCancelableCustomEvent,
  RadioGroup,
} from "@amzn/awsui-components-react";
import {
  FormField,
  RadioGroupProps,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import { IClaimActualsContainerProps } from "external/programs/migration-acceleration-program/2024/fund-request/views/MAPCashClaimActualsSubmission";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { VMWARE_BASE_RATES } from "./constants/vmware_rates";
import { ToolsUsedForGeneratingMigrationReport } from "shared/util/constants/staticOptions";

const VMwareMSPRadioGroup = ({
  data,
  setData,
}: IClaimActualsContainerProps) => {
  // TODO: Ticket for feature_flag: https://issues.amazon.com/issues/APN-53745. Ticket to remove flag: https://taskei.amazon.dev/tasks/APN-55282
  useEffect(() => {
    const checkVMwareFeature = async () => {
      const vmwareEnabledFlag = await isFeatureEnabled(
        FEATURE_FLAG_ENUMS.VMWARE_BONUS_CLAIM_CLAIM_ADJUSTMENT
      );
      if (vmwareEnabledFlag) {
        const updatedClaimAmount = recalculateVMwareClaimAmount(
          data?.isVMwareManagedServicesInScope,
          data?.numberOfVmwareToMigrate
        );
        setData((prev) => ({
          ...prev,
          claimAmount: updatedClaimAmount,
        }));
      }
    };

    checkVMwareFeature();
  }, [data?.isVMwareManagedServicesInScope, data?.numberOfVmwareToMigrate]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      isVMwareManagedServicesInScope: data?.isVMwareManagedServicesInScope
        ? "yes"
        : "no",
    }));
  }, []);

  const recalculateVMwareClaimAmount = (
    isVMwareManagedServicesInScope: string,
    numberOfVmwareToMigrate: number
  ): string => {
    const baseRate =
      isVMwareManagedServicesInScope === "yes"
        ? VMWARE_BASE_RATES.WITH_MANAGED_SERVICES
        : VMWARE_BASE_RATES.WITHOUT_MANAGED_SERVICES;
    const value = baseRate * numberOfVmwareToMigrate;
    return value.toString();
  };

  const extractIsVMwareManagedServicesInScopeValueFromData = () => {
    if (data?.isVMwareManagedServicesInScope === undefined || null) {
      return "yes";
    }
    return data?.isVMwareManagedServicesInScope;
  };

  const radioItems: RadioGroupProps.RadioButtonDefinition[] = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  return (
    <FormField
      label="Is Managed Services in scope"
      description="Is Managed Services still in the scope of services being offered to the customer?"
    >
      <SpaceBetween size="l">
        <RadioGroup
          onChange={(
            event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>
          ) => {
            setData((prev) => ({
              ...prev,
              isVMwareManagedServicesInScope: event.detail.value,
            }));
          }}
          value={extractIsVMwareManagedServicesInScopeValueFromData()}
          items={radioItems}
        />
      </SpaceBetween>
    </FormField>
  );
};

export default VMwareMSPRadioGroup;
