export const COMPONENT_TYPE = {
  CHECKBOX: "checkbox",
  INPUT: "input",
  DATEPICKER: "datepicker",
  SELECT: "select",
  MULTI_SELECT: "multiselect",
  OPPORTUNITY_SELECT: "opportunityselect",
  SCA: "strategicCollaborationAgreement",
  PSA_SELECT: "psaselect",
  TEXTAREA: "textarea",
  FILE_UPLOAD: "fileupload",
  KEY_VALUE: "keyvalue",
};
