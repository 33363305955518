import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import PropTypes from "prop-types";

import Loading from "../../../../../../shared/components/common/Loading";

import {
  filterFields,
  getTemplate,
  schema,
} from "../../../../../config/schema";
import useFields from "../../../../../../shared/util/hooks/useFields";
import { SpaceBetween } from "@amzn/awsui-components-react";
import AddButton from "../../../../../../shared/components/FundRequest/StepsContainer/AddButton";
import RemoveButton from "../../../../../../shared/components/FundRequest/StepsContainer/RemoveButton";
import { useOptionsContext } from "../../../../../util/context/OptionsContext";
import { PROGRAM_TYPE } from "../../../../../../shared/util/constants/programType";
import Section from "../../../../../../shared/components/FundRequest/StepsContainer/Section";
import { DisableFactory } from "../../Inputs/PropBuilder/DisableFactory";
import { ValueFactory } from "../../Inputs/PropBuilder/ValueFactory";
import { InputPropsBuilder } from "../../../../../../shared/components/FundRequest/InputPropsBuilder";
import { InputFactory } from "../../../../../../shared/components/FundRequest/InputFactory";
import { getFundRequest } from "../../../../../util/services/data/FundRequestService";
import { FileUploadTriggers } from "../../../../../util/services/data/dataFactory";
import {
  getSectionConstant,
  SECTION,
  SECTION_CONSTANT_TYPE,
} from "../../../../../../shared/util/constants/section";
import Link from "@amzn/awsui-components-react/polaris/link";
import { SCATableSelector } from "../../Inputs/SCATableSelect/TableSelector";
import { SCATableContainer } from "../../Inputs/SCATableSelect/Table";

const CashRequestSectionContainer = ({
  data,
  errors,
  dataInput,
  dataLoad,
  fieldToIdMap,
  updateErrors,
  setFieldToIdMap,
  stepTitle,
  validators,
  showHelperPanel,
}) => {
  const [options] = useOptionsContext();
  const stepTemplate = schema[stepTitle];
  const [
    { additionalSections, fieldTemplate, isLoading, addSection, removeSection },
  ] = useFields({
    data,
    stepTemplate,
    fieldToIdMap,
    updateFieldMap: setFieldToIdMap,
    dataLoad,
    dataInput,
    getTemplate: getTemplate,
    filterFields,
    schema,
    getFundRequest,
  });

  const template = getTemplate({
    program: data["program"],
    fundingType: data["fundingType"],
  });
  const stepLayout = template[stepTemplate.name];

  const showAddAnotherActivityButton = () => {
    return ![PROGRAM_TYPE.POC].includes(data.program);
  };

  const NumberOfClaims = () => {
    const [numOfClaims] = fieldTemplate[stepTemplate.name].fields.filter(
      (field) => field.name === "numberOfClaims"
    );
    return (
      <InputFactory
        componentType={numOfClaims.component}
        id={numOfClaims.id}
        value={additionalSections.length}
        dataInput={dataInput}
        name={numOfClaims.name}
        disabled={true}
        label={numOfClaims.label}
        placeholder={numOfClaims.placeholder}
        fileuploadProp={FileUploadTriggers}
        showHelperPanel={showHelperPanel}
      />
    );
  };

  const fundingTypes = ["Cash"];
  const budgetIdItem = "budgetIdCash";

  const { isSCALoading, enableSCA, budgets } = SCATableSelector({
    data,
    fieldTemplate,
    fundingTypes,
  });

  const section =
    isLoading || isSCALoading ? (
      <Loading />
    ) : (
      <SpaceBetween>
        {enableSCA && (
          <SCATableContainer
            data={data}
            dataInput={dataInput}
            budgets={budgets}
            budgetIdItem={budgetIdItem}
          />
        )}
        <Section
          header={
            <Header
              variant="h2"
              info={
                <Link
                  variant="Info"
                  onFollow={() =>
                    showHelperPanel({
                      title: stepTitle,
                      keys: [
                        getSectionConstant({
                          type: SECTION_CONSTANT_TYPE.NAME,
                          value: stepTitle,
                        }),
                      ],
                      open: true,
                    })
                  }
                >
                  Info
                </Link>
              }
              description={
                <React.Fragment>{schema[stepTitle].description}</React.Fragment>
              }
            >
              {stepTitle}
            </Header>
          }
        >
          <React.Fragment>
            {fieldTemplate &&
              fieldTemplate[stepTemplate.name] &&
              fieldTemplate[stepTemplate.name].fields
                .filter((field) => field.name !== "numberOfClaims")
                .map((field, index) => {
                  const inputProps = InputPropsBuilder({
                    data,
                    errors,
                    field,
                    options,
                    fieldToIdMap,
                    disableFactory: DisableFactory,
                    valueFactory: ValueFactory,
                  });

                  return (
                    <InputFactory
                      key={index}
                      dataInput={dataInput}
                      fieldToIdMap={fieldToIdMap}
                      updateErrors={updateErrors}
                      validators={validators}
                      showHelperPanel={showHelperPanel}
                      {...inputProps}
                    />
                  );
                })}
            <NumberOfClaims />
          </React.Fragment>
        </Section>
        <br />
        {additionalSections.map((sectionIndex) => {
          const templateName =
            sectionIndex > 1
              ? `cashClaimPlans-${sectionIndex}`
              : "cashClaimPlans";
          return (
            <React.Fragment key={sectionIndex}>
              <Section
                header={
                  <SpaceBetween size="l">
                    <Header
                      variant="h2"
                      actions={
                        additionalSections.length > 1 ? (
                          <RemoveButton
                            index={sectionIndex}
                            removeSection={removeSection}
                          />
                        ) : (
                          ""
                        )
                      }
                    >
                      {`${SECTION.CASH_CLAIM_PLANS} ${
                        sectionIndex > 1 ? sectionIndex : ""
                      }`}
                    </Header>
                  </SpaceBetween>
                }
              >
                <React.Fragment>
                  {fieldTemplate &&
                    fieldTemplate[templateName] &&
                    fieldTemplate[templateName].fields.map((field, index) => {
                      const inputProps = InputPropsBuilder({
                        data,
                        errors,
                        field,
                        options,
                        index: sectionIndex,
                        fieldToIdMap,
                        disableFactory: DisableFactory,
                        valueFactory: ValueFactory,
                      });

                      return (
                        <InputFactory
                          key={index}
                          dataInput={dataInput}
                          fieldToIdMap={fieldToIdMap}
                          updateErrors={updateErrors}
                          validators={validators}
                          showHelperPanel={showHelperPanel}
                          {...inputProps}
                        />
                      );
                    })}
                </React.Fragment>
              </Section>
              <br />
            </React.Fragment>
          );
        })}

        {showAddAnotherActivityButton() && (
          <AddButton
            addSection={addSection}
            multiSectionDetails={stepLayout.multiSection}
            isDisabled={
              !(additionalSections.length < stepLayout.multiSection.maxSections)
            }
          />
        )}
      </SpaceBetween>
    );
  return <SpaceBetween>{section}</SpaceBetween>;
};

CashRequestSectionContainer.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  dataInput: PropTypes.func,
  dataLoad: PropTypes.func,
  fieldToIdMap: PropTypes.object,
  updateErrors: PropTypes.func,
  setTemplate: PropTypes.func,
  validators: PropTypes.any,
  setFieldToIdMap: PropTypes.func,
  stepTitle: PropTypes.string,
  showHelperPanel: PropTypes.func,
};

export default React.memo(CashRequestSectionContainer);
