import React from "react";

export const generateSCALink = (budgetId, fundingContractName, baseUrl) => {
  return (
    <a
      href={`${baseUrl}/${budgetId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {fundingContractName}
    </a>
  );
};
